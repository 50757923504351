import { UserDto } from '../sherpath-course-management-service/sherpath-course-management-service.dtos';
import { QtiDataDto } from '../eols-assessment-service/eols-assessment-service.dtos';

export type AuthessHealthResponse = {
  fixedAssignmentIds: number[];
  passedAssignmentIds: number[];
}

export interface MasteryResetDto {
  job: number;
  attempts: number;
  isbn: string;
  jobId: number;
  resetBy: number;
  status: string;
  userId: number;
  createdAt: string;
}

export interface CourseISBNDto {
  title: string;
  isbn: string;
}

export type EaqUserDto = UserDto

export type EaqStudentPerformanceDto = {
  masteryLevel: number;
  questionsAnswered: number;
  questionsCorrect: number;
  questionsConfident: number;
  eolsUser: {
    id: number;
    lmsExternalId: string;
    externalId: string;
    firstName: string;
    lastName: string;
    username: string;
    emailAddress: string;
    lastSeenDate: unknown;
    updatedAt: string;
    type: string;
    eolsUserRoles: unknown[];
  };
  timeSpent: number;
}

export type EaqTopicDto = {
  displayName: string;
  displayOrder: number;
  id: string;
  leaf: boolean;
  level: number;
  root: boolean;
  studentPerformances: EaqStudentPerformanceDto[];
  text: string;
  vtwId: string;
  children?: EaqTopicDto[];
}

export type EaqTaxonomyDto = {
  id: string;
  vtwId: string;
  text: string;
  root: boolean;
  leaf: boolean;
  displayOrder: number;
  level: number;
  displayName?: string;
  children?: EaqTaxonomyDto[];
  description?: string;
}

export type EaqRecommenderQuestionsDto = {
  keyword: string;
  page: number;
  size: number;
  questionVtwIds: string[];
  isbnTopicMap: Record<string, string[]>;
  questionCount: boolean;
  courseISBNS: string[];
  excludedQuestionIds: string[];
  questionTypes: string[];
}

export type AuthessAssignmentsMapDto = Record<string, boolean>

export type AuthessAssignmentDto = {
  id: number;
  key: string;
  title: string;
  isbn: string;
  numberOfQuestions: number;
}
export type TaxonomyRootPathDto = {
  id: string;
  text: string;
  level: number;
  eaqISBN: string;
  thresholdScore: string;
  totalScore: string;
  totalQuestions: string;
  averageNormalizedDifficulty: number;
}

export type TaxonomyPathDto = {
  taxonomyNodeId: string;
  orderedTaxonomyRootPath: TaxonomyRootPathDto[];
}

export type EaqQuestionDetailDto = {
  vtwId: string;
  qtiData: QtiDataDto;
  taxonomyPaths: TaxonomyPathDto[];
  difficulty: number;
  normalizedDifficulty: number;
  quiztopicIds: string[];
}

export enum BookTaxonomyKindDto {
  ALL = 'ALL',
  PRIMARY = 'PRIMARY'
}
