import { createSelector } from 'reselect';
import { cwSelectors } from './courseware.selectors';
import {
  CoursewareStore,
  ReduxPage,
  ReduxPageWithCatalog,
  ReduxPageWithPrimaryTaxonomies
} from './courseware.models';

const {
  getCwStore,
} = cwSelectors;

const makeGetReduxPage = <T extends ReduxPage>(reduxPage: T) => (globalState): CoursewareStore[T] => getCwStore(globalState)[reduxPage];

export const makeGetCatalogInReduxPage = (reduxPage: ReduxPageWithCatalog) => {
  return createSelector(
    [makeGetReduxPage(reduxPage)],
    (page) => {
      return page.catalog;
    }
  );
};

export const makeGetPrimaryTaxonomiesInReduxPage = (reduxPage: ReduxPageWithPrimaryTaxonomies) => {
  return createSelector(
    [makeGetReduxPage(reduxPage)],
    (page) => {
      return page.primaryTaxonomies;
    }
  );
};
