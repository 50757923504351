import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  PrimaryTaxonomyDto,
  RecLearningType
} from './rec-gateway.models';
import { CatalogWithExternalEntitiesDto } from '../sherpath-course-management-service/sherpath-course-management-service.dtos';

import { EaqQuestionDetailDto } from '../eaq-app-facade-service/eaq-app-facade-service.dtos';

export const fetchCatalog = (
  isbns: string[]
): Promise<CatalogWithExternalEntitiesDto> => {
  return withApiErrorLoggingOnly(() => {
    const url = addSearchParams('/recommendation/learning/catalog', {
      'filter[isbns]': isbns,
      included: 'TAXONOMY',
      'filter[learningType]': [
        RecLearningType.ADAPTIVE_LESSON,
        RecLearningType.EBOOK_READING
      ]
    });

    return eolsBaseApi.get(url);
  });
};

export const fetchPrimaryTaxonomy = (isbn: string): Promise<PrimaryTaxonomyDto> => {
  return withApiErrorLoggingOnly(() => {
    const url = encodeURI(`/sherpath/course-management/recommendation/taxonomy/isbns/${isbn}/primaryTaxonomies`);
    return eolsBaseApi.get(url);
  });
};

export const fetchQuestions = (eaqISBN: string, vtwIds: string[]): Promise<EaqQuestionDetailDto[]> => {
  return withApiErrorLoggingOnly(() => {
    const url = encodeURI(`/recommendation/eaq/isbn/${eaqISBN}/questions`);
    return eolsBaseApi.post(url, { data: vtwIds });
  });
};
