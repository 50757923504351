import moment from 'moment';
import {
  Dictionary,
  isInteger,
  isNil,
  omit,
  toString
} from 'lodash';
import { IntlShape } from 'react-intl';
import React from 'react';
import { ELSWithToastService } from '@els/els-component-toast-react';
import {
  AssignmentDto,
  AssignmentGradeType,
  AssignmentTargetType,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  SyllabusItemDto,
  SyllabusItemExternalIdDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  checkAssignmentHasAvailableDate,
  checkAssignmentHasDueDate,
  checkAssignmentIsAssigned,
} from '../../components/assignment-editor/assignment-editor.utilities';
import {
  getLocalMomentInsFromServicesUTC,
  isValidDate
} from '../../utilities/app.utilities';
import {
  mapToIds,
} from '../../utilities/common.utilities';
import { getTitle } from '../catalog/catalog.utilities';
import {
  getBookTaxonomy,
  getChapterIdsFromPageRanges,
  getChapterPageRangesFromPageRanges,
  getDefaultChapterPageRanges,
  getEbookAssignmentInfo,
} from '../ebook-assignment-editor/ebook-assignment.utilities';
import {
  assignmentHasDueDate,
  getAssignmentTypeFromSyllabusItemType,
  getCatalogItemFromSyllabusItem,
  getIsbnFromSyllabusItem,
  getPageRangesFromEbookSyllabusItem,
  getSyllabusItemTypeFromAssignment,
  isAssignmentDueDateInPast,
  isScorableType,
} from '../course-plan/syllabus.utilities';
import { EvolveProductType } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import {
  ReduxPage,
  ReduxPageWithPrimaryTaxonomies
} from '../../redux/courseware/courseware.models';
import {
  ActiveSyllabusItemTypeDto,
  ExternalIdTypeDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { getSherpathLessonAssignmentInfo } from '../sherpath-lesson-editor/sherpath-lesson.utilities';
import { getSimulationAssignmentInfo } from '../simulation-editor/simulation.utilities';
import { getSkillAssignmentInfo } from '../skill-assignment-editor/skill-assignment.utilities';
import { getEvolveResourceAssignmentInfo } from '../evolve-resource-assignment-editor/evolve-resource-assignment.utilities';
import {
  MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH,
} from '../course-plan/syllabus.constants';
import {
  getCurrentDateISOString,
  getDefaultDueDateString
} from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import {
  CatalogWithExternalEntitiesDto,
  UserDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import {
  BulkEditorError,
  BulkEditSettingsField,
} from './bulk-edit-settings.models';
import {
  availableDateNullErrorMessage,
  dueDateNullErrorMessage,
  extendDatesOptions,
  dueDateBeforeNowErrorMessage,
  PassFailOnlyAssignmentType,
  ScoredOnlyAssignmentType
} from './bulk-edit-settings.constants';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { ELSToastServiceType } from '../../models/els.models';
import { Messages } from '../../translations/message.models';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import { getShadowHealthAssignmentInfo } from '../shadow-health-assignment-editor/shadow-health-assignment.utilities';
import { getOsmosisVideoAssignmentInfo } from '../osmosis-video-editor/osmosis-video.utilities';
import { getSherpathGroupActivityAssignmentInfo } from '../sherpath-group-activity-editor/sherpath-group-activity.utilities';
import { getSherpathPowerpointAssignmentInfo } from '../sherpath-powerpoint-editor/sherpath-powerpoint.utilities';
import { getSherpathCaseStudyAssignmentInfo } from '../../utilities/migrated-utilities';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';

export const areDatesTheSame = (dateA: string, dateB: string): boolean => {

  if (isNil(dateA) && isNil(dateB)) {
    return true;
  }

  if (isNil(dateA) && !isNil(dateB)) {
    return false;
  }

  if (isNil(dateB) && !isNil(dateA)) {
    return false;
  }

  if (dateA === dateB) {
    return true;
  }

  return getLocalMomentInsFromServicesUTC(dateA, moment.ISO_8601)
    .isSame(getLocalMomentInsFromServicesUTC(dateB, moment.ISO_8601));
};

export const areDueDatesSame = (assignmentA: AssignmentDto, assignmentB: AssignmentDto): boolean => {

  if (!assignmentA && !assignmentB) {
    return true;
  }

  const dateA = assignmentA ? assignmentA.dueDate : null;
  const dateB = assignmentB ? assignmentB.dueDate : null;

  return areDatesTheSame(dateA, dateB);

};

export const validateUpdatedAssignment = (
  assignment: AssignmentDto,
  originalAssignment: AssignmentDto,
  messages: Messages,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): BulkEditorError[] => {

  const errors = [];

  if (!assignment) {
    return errors;
  }

  const hasAvailableDate = checkAssignmentHasAvailableDate(assignment);
  const availableDateMomentIns = getLocalMomentInsFromServicesUTC(assignment.availableDate);
  if (hasAvailableDate && !isValidDate(assignment.availableDate, moment.ISO_8601)) {
    errors.push({
      message: messages.INVALID_AVAILABILITY_DATE,
      fields: [BulkEditSettingsField.AVAILABLE_FROM]
    });
  }

  const hasDueDate = checkAssignmentHasDueDate(assignment);
  if (hasDueDate && !hasAvailableDate) {
    errors.push({
      message: availableDateNullErrorMessage, // TODO: Migrate to messages
      fields: [BulkEditSettingsField.DUE]
    });
  }

  const isDueDateUpdated = !areDueDatesSame(originalAssignment, assignment);
  if (isDueDateUpdated && hasDueDate && moment.utc().isAfter(moment.utc(assignment.dueDate))) {
    errors.push({
      message: dueDateBeforeNowErrorMessage, // TODO: Migrate to messages
      fields: [BulkEditSettingsField.DUE]
    });
  }

  const shouldValidateDueDate = checkAssignmentIsAssigned(assignment);
  const dueDateMomentIns = getLocalMomentInsFromServicesUTC(assignment.dueDate);
  if (shouldValidateDueDate && !isValidDate(assignment.dueDate, moment.ISO_8601)) {
    errors.push({
      message: messages.INVALID_DUE_DATE,
      fields: [BulkEditSettingsField.DUE]
    });
  }

  if (shouldValidateDueDate
    && dueDateMomentIns.isBefore(availableDateMomentIns)) {
    errors.push({
      message: messages.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE,
      fields: [BulkEditSettingsField.AVAILABLE_FROM, BulkEditSettingsField.DUE]
    });
  }

  if (!hasDueDate && assignment && assignment.assignmentGradeType && assignment.assignmentGradeType !== AssignmentGradeType.NOT_GRADED) {
    errors.push({
      message: dueDateNullErrorMessage, // TODO: Migrate to messages
      fields: [BulkEditSettingsField.GRADING]
    });
  }

  const syllabusItemType = getSyllabusItemTypeFromAssignment(assignment, null);
  if (hasAvailableDate && !hasDueDate && syllabusItemType === ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ) {
    errors.push({
      message: messages.DUE_DATE_IS_REQUIRED_FOR_ALL_EAQ_ASSIGNMENTS,
      fields: [BulkEditSettingsField.DUE]
    });
  }

  if (
    assignment
    && assignment.assignmentGradeType === AssignmentGradeType.SCORED
    && Object.values(PassFailOnlyAssignmentType).includes(assignment.assignmentType)
  ) {
    errors.push({
      message: messages.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_SCORE_GRADING,
      fields: [BulkEditSettingsField.GRADING]
    });
  }

  if (
    assignment
    && assignment.assignmentGradeType === AssignmentGradeType.PASS_FAIL
    && Object.values(ScoredOnlyAssignmentType).includes(assignment.assignmentType)
  ) {
    errors.push({
      message: messages.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_PASS_FAIL_GRADING,
      fields: [BulkEditSettingsField.GRADING]
    });
  }

  return errors;
};

export const associateSyllabusItemWithAssignment = (syllabusItem: SyllabusItemDto, assignmentId: string | number): SyllabusItemDto => {
  return {
    ...syllabusItem,
    externalIdentifiers: [
      ...syllabusItem.externalIdentifiers,
      {
        type: ExternalIdTypeDto.ASSIGNMENT_ID,
        value: toString(assignmentId),
      } as SyllabusItemExternalIdDto
    ],
  };
};

export const getUpdatedSyllabusItemsState = (syllabusItems: SyllabusItemDto[], selectedSyllabusItem: SyllabusItemDto, assignmentId: string | number): SyllabusItemDto[] => {
  return syllabusItems.map(syllabusItem => {
    if (syllabusItem.id === selectedSyllabusItem.id) {
      return associateSyllabusItemWithAssignment(syllabusItem, assignmentId);
    }
    return syllabusItem;
  });
};

export const getDefaultAssignment = (
  syllabusItem: SyllabusItemDto,
  courseSectionId: string,
  catalog: CatalogWithExternalEntitiesDto,
  users: UserDto[],
  primaryTaxonomies: PrimaryTaxonomy[],
  hasDueDate = false,
  hasAvailableDate = true,
): Partial<AssignmentDto> => {

  const assignmentType = getAssignmentTypeFromSyllabusItemType(syllabusItem.type);
  const catalogItemIsbn = getIsbnFromSyllabusItem(syllabusItem, catalog);
  const availableDate = hasAvailableDate ? getCurrentDateISOString() : null;
  const contentItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);

  if (!contentItem) {
    return null;
  }

  return {
    id: -(Math.floor(Number(new Date()) * Math.random())),
    targetType: AssignmentTargetType.COURSE,
    assignmentGoals: [],
    assignmentType,
    availableDate,
    dueDate: hasDueDate ? getDefaultDueDateString(availableDate) : null,
    assignmentGradeType: AssignmentGradeType.NOT_GRADED,
    courseSectionId: parseInt(courseSectionId, 10),
    students: users.filter(x => x.type === 'Stu').map(mapToIds),
    isbn: catalogItemIsbn,
    studyMode: false,
    title: getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
    contentId: contentItem ? contentItem.attributes.contentId : null
  };
};

type GetAssignmentInfoProps = {
  syllabusItem: SyllabusItemDto;
  newAssignment: AssignmentDto;
  fetchPrimaryTaxonomies: (
    primaryTaxonomies: PrimaryTaxonomy[],
    useCache: boolean,
    reduxPage?: ReduxPageWithPrimaryTaxonomies,
  ) => Promise<PrimaryTaxonomy[]>;
  catalog: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
  fetchLessonTopicsAction: (lessonId: string) => Promise<string[]>;
}

type GetAssignmentInfoHandler = (props: GetAssignmentInfoProps) => Promise<AssignmentDto>

const defaultAssignmentInfoHandler = (props: GetAssignmentInfoProps): Promise<AssignmentDto> => {
  return Promise.resolve(props.newAssignment);
};

const evolveResourceAssignmentInfoHandler = (props: GetAssignmentInfoProps) => {
  const {
    newAssignment,
    catalog,
    primaryTaxonomies,
    syllabusItem
  } = props;
  const contentItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);
  const assignmentInfo = getEvolveResourceAssignmentInfo(catalog, contentItem, primaryTaxonomies);
  return Promise.resolve({
    ...newAssignment,
    ...assignmentInfo,
  });
};

const commonAssignmentInfoHandler = (
  handler: (
    catalog: CatalogWithExternalEntitiesDto,
    contentItem: RecContentItemDto,
    primaryTaxonomies: PrimaryTaxonomy[]
  ) => Partial<AssignmentDto>
) => (props: GetAssignmentInfoProps) => {
  const {
    newAssignment,
    syllabusItem,
    catalog,
    primaryTaxonomies
  } = props;
  const contentItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);
  const assignmentInfo = handler(catalog, contentItem, primaryTaxonomies);
  return Promise.resolve({
    ...newAssignment,
    ...assignmentInfo,
  });
};

const AssignmentInfoHandlerMap: Record<ActiveSyllabusItemTypeDto, GetAssignmentInfoHandler> = {
  [ActiveSyllabusItemTypeDto.EBOOK_READING]: (props) => {
    const {
      newAssignment,
      fetchPrimaryTaxonomies,
      syllabusItem
    } = props;
    const pageRanges = getPageRangesFromEbookSyllabusItem(syllabusItem);
    return fetchPrimaryTaxonomies([{
      isbn: newAssignment.isbn,
      isbnType: EvolveProductType.EBOOK,
      taxonomy: null
    }], true, ReduxPage.EBOOK_EDITOR_PAGE).then((ebookPrimaryTaxonomies) => {
      const taxonomy = getBookTaxonomy(newAssignment.isbn, ebookPrimaryTaxonomies);
      const checkedChapterIds = getChapterIdsFromPageRanges(pageRanges, taxonomy);
      const chapterPageRanges = {
        ...getDefaultChapterPageRanges(taxonomy),
        ...getChapterPageRangesFromPageRanges(pageRanges, taxonomy)
      };
      const assignmentInfo = getEbookAssignmentInfo(taxonomy, checkedChapterIds, newAssignment, chapterPageRanges);
      return {
        ...newAssignment,
        ...assignmentInfo,
      };
    });
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_LESSON]: (props) => {
    const {
      newAssignment,
      syllabusItem,
      catalog,
      primaryTaxonomies,
      fetchLessonTopicsAction
    } = props;
    const contentItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);
    return fetchLessonTopicsAction(contentItem.attributes.contentId).then((lessonTopics) => {
      const assignmentInfo = getSherpathLessonAssignmentInfo(catalog, contentItem, primaryTaxonomies, newAssignment, lessonTopics);
      return {
        ...newAssignment,
        ...assignmentInfo,
      };
    });
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS]: commonAssignmentInfoHandler(getSimulationAssignmentInfo),
  [ActiveSyllabusItemTypeDto.SHERPATH_SKILL]: commonAssignmentInfoHandler(getSkillAssignmentInfo),
  [ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT]: commonAssignmentInfoHandler(getShadowHealthAssignmentInfo),
  [ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO]: commonAssignmentInfoHandler(getOsmosisVideoAssignmentInfo),
  [ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY]: commonAssignmentInfoHandler(getSherpathGroupActivityAssignmentInfo),
  [ActiveSyllabusItemTypeDto.SHERPATH_POWERPOINT]: commonAssignmentInfoHandler(getSherpathPowerpointAssignmentInfo),
  [ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY]: commonAssignmentInfoHandler(getSherpathCaseStudyAssignmentInfo),
  [ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE]: evolveResourceAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE]: evolveResourceAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.FOLDER]: defaultAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY]: defaultAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.SIMCHART_CHART]: defaultAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.ASSESSMENT_BUILDER_ASSIGNMENT]: defaultAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON]: defaultAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ]: defaultAssignmentInfoHandler,
  [ActiveSyllabusItemTypeDto.CUSTOM_LINK]: defaultAssignmentInfoHandler,
};

export const getAssignmentInfo = (props: GetAssignmentInfoProps): Promise<AssignmentDto> => {
  const handler = AssignmentInfoHandlerMap[props.syllabusItem.type];
  return handler(props).then(assignment => {
    return omit(assignment, 'id') as AssignmentDto;
  });
};

export const getDueDateDisableBefore = () => {
  return moment().toDate();
};

export const canSyllabusItemHaveDueDate = (syllabusItem: SyllabusItemDto) => {
  return isScorableType(syllabusItem.type);
};

export const areAvailableDatesSame = (assignmentA: AssignmentDto, assignmentB: AssignmentDto): boolean => {

  if (!assignmentA && !assignmentB) {
    return true;
  }

  const dateA = assignmentA ? assignmentA.availableDate : null;
  const dateB = assignmentB ? assignmentB.availableDate : null;

  return areDatesTheSame(dateA, dateB);
};

export const isAssignmentPastDateExtended = (originalAssignment: AssignmentDto, updatedAssignment: AssignmentDto): boolean => {
  if (areDueDatesSame(originalAssignment, updatedAssignment)) {
    return false;
  }
  return originalAssignment && assignmentHasDueDate(originalAssignment) && isAssignmentDueDateInPast(originalAssignment);
};

export const isTitleSame = (syllabusItem: SyllabusItemDto, originalAssignment: AssignmentDto, assignment: AssignmentDto) => {
  if (originalAssignment) {
    return originalAssignment.title === assignment.title;
  }
  if (assignment) {
    return syllabusItem.title === assignment.title;
  }
  return true;
};

export const toastStartedAssignmentWarning = (toastService: ELSToastServiceType, messages: Messages) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (<div>{messages.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_EDIT_GRADING_STARTED_ASSIGNMENT_WARNING}</div>),
    type: ELSWithToastService.types.NEGATIVE,
  });
};

export const toastStartedAssignmentApplyAllWarning = (toastService: ELSToastServiceType, messages: Messages) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (<div>{messages.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_GRADING_STARTED_ASSIGNMENT_WARNING}</div>),
    type: ELSWithToastService.types.NEGATIVE,
  });
};

export const toastUnpublishedLessonWarning = (toastService: ELSToastServiceType, syllabusItem: SyllabusItemDto, intl: IntlShape) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>{
        intl.formatMessage({
          id: LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_WARNING,
          defaultMessage: '',
          description: ''
        }, {
          syllabusItemName: <strong>{syllabusItem.title}:</strong>
        })
      }
      </div>
    ),
    type: ELSWithToastService.types.NEGATIVE,
  });
};

export const toastUnpublishedLessonSkippedWarning = (toastService: ELSToastServiceType, messages: Messages) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        {messages.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_SKIPPED_WARNING}
      </div>
    ),
    type: ELSWithToastService.types.PRIMARY,
  });
};

export const toastNotSupportedWarning = (toastService: ELSToastServiceType) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        This assignment type is not supported in the bulk assignment editor.
        Please return to the Course Plan and assign this syllabus item individually.
      </div>
    ),
    type: ELSWithToastService.types.NEGATIVE,
  });
};

export const toastNotSupportedItemsSkippedWarning = (toastService: ELSToastServiceType) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        Changes not applied to SimChart assignments that have not been previously assigned.
        Please return to the Course Plan and assign each SimChart individually before updating in the bulk assignment editor.
      </div>
    ),
    type: ELSWithToastService.types.PRIMARY,
  });
};

export const toastAvailabilityDateApplySuccess = (toastService: ELSToastServiceType) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (<div>Availability date applied</div>),
    type: ELSWithToastService.types.POSITIVE,
  });
};

export const toastDueDateApplySuccess = (toastService: ELSToastServiceType) => {
  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (<div>Due date applied</div>),
    type: ELSWithToastService.types.POSITIVE,
  });
};

export const isGradeTypeUpdated = (originalAssignment: AssignmentDto, assignment: AssignmentDto) => {
  return originalAssignment
    ? originalAssignment.assignmentGradeType !== assignment.assignmentGradeType
    : assignment && assignment.assignmentGradeType && assignment.assignmentGradeType !== AssignmentGradeType.NOT_GRADED;
};

export const getPushDates = (availableDate: string, minutes: number, hours: number, days: number, weeks: number, selectedOptions: string) => {
  if (selectedOptions === extendDatesOptions[0].value) {
    return moment.utc(availableDate).add({ minutes, hours, days, weeks }).toISOString();
  }
  return moment.utc(availableDate).add({ minutes: -minutes, hours: -hours, days: -days, weeks: -weeks }).toISOString();
};

export const isInValidPushDateData = (pushDateData: number[] | string[]): boolean => {
  return pushDateData.some(item => ((item === '') || Number(item) < 0 || Number(item) > 999 || !isInteger(Number(item))));
};

export const hasAnyAssignmentBeenUpdated = (originalAssignmentsDictionary: Dictionary<AssignmentDto>, updatedAssignmentsDictionary: Dictionary<AssignmentDto>): boolean => {
  const originalAssignments = Object.values(originalAssignmentsDictionary);
  const updatedAssignments = Object.values(updatedAssignmentsDictionary);

  return updatedAssignments.some(updatedAssignment => {
    const originalAssignment = originalAssignments.find(original => {
      return original.id === updatedAssignment.id;
    });
    if (!originalAssignment) {
      return true;
    }
    return originalAssignment.assignmentGradeType !== updatedAssignment.assignmentGradeType
      || !areDatesTheSame(originalAssignment.availableDate, updatedAssignment.availableDate)
      || !areDatesTheSame(originalAssignment.dueDate, updatedAssignment.dueDate);
  });
};
