import { get, toString } from 'lodash';
import { ELSAssignmentEditorGradeOption } from '@els/els-component-shared-ts-react';
import { CatalogWithExternalEntitiesDto } from '../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { RecContentItemDto } from '../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../apis/rec-gateway/rec-gateway.models';
import { AssignmentDto, AssignmentGradeType, AssignmentType } from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import { getSubtitle, getTitle } from '../pages/catalog/catalog.utilities';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../pages/course-plan/syllabus.constants';
import { PassFailOnlyAssignmentType, ScoredOnlyAssignmentType } from '../pages/bulk-edit-settings/bulk-edit-settings.constants';
import { defaultGradingOptions } from '../components/assignment-editor/assignment-editor.utilities';
import { SyllabusItemDto, SyllabusItemExternalIdDto } from '../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { ExternalIdTypeDto } from '../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';

export const getSherpathCaseStudyAssignmentInfo = (catalog: CatalogWithExternalEntitiesDto, contentItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]): {
  assignmentGoals: AssignmentDto['assignmentGoals'];
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
  contentId: AssignmentDto['contentId'];
} => {
  const contentId = get(contentItem, 'attributes.contentId', null);
  return {
    assignmentGoals: [{
      id: null,
      goal: 100,
      vtwId: contentId,
      text: null,
    }],
    title: contentItem ? getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    subtitle: contentItem ? getSubtitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    contentId
  };
};

export const getAssignmentEditorGradingOptions = (assignmentType: AssignmentType): ELSAssignmentEditorGradeOption[] => {
  if (Object.values(PassFailOnlyAssignmentType).includes(assignmentType)) {
    return defaultGradingOptions.filter(item => (item.value as string) !== AssignmentGradeType.SCORED);
  }
  if (Object.values(ScoredOnlyAssignmentType).includes(assignmentType)) {
    return defaultGradingOptions.filter(item => (item.value as string) !== AssignmentGradeType.PASS_FAIL);
  }
  return defaultGradingOptions;
};

export const associateSyllabusItemWithAssignment = (syllabusItem: SyllabusItemDto, assignmentId: string | number): SyllabusItemDto => {
  return {
    ...syllabusItem,
    externalIdentifiers: [
      ...syllabusItem.externalIdentifiers,
      {
        type: ExternalIdTypeDto.ASSIGNMENT_ID,
        value: toString(assignmentId),
      } as SyllabusItemExternalIdDto
    ],
  };
};
