import { RecContentItemTypeDto } from '../../apis/rec-gateway/rec-gateway.dtos';

export const ALL_OPTION_VALUE = 'ALL';
export const ALL_OPTION_NAME = 'All';

export const SyllabusFolderOptionType = {
  ADD_FOLDER: {
    name: 'New Folder',
    value: 'ADD_NEW_SYLLABUS_FOLDER',
  },
  SELECT_FOLDER: {
    name: '--Select Folder--',
    value: 'SELECT_SYLLABUS_FOLDER'
  }
};

export enum EbookPageRangeSeparators {
  CHUNK_SEPARATOR = ',',
  RANGE_SEPARATOR = '-',
}

export const CONFIRM_BULK_ADD_MODAL_ID = 'CONFIRM_BULK_ADD_MODAL_ID';

export const DEFAULT_PROMOTION_CODE = '20006';

export const RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS = 14;

export const LOCATION_PATHNAME = 'location.pathname';

export enum CatalogPageParam {
  STATIC_RESOURCE_TYPE = 'staticResourceType',
  TARGET_SYLLABUS_ITEM_ID = 'targetSyllabusItemId',
  REF = 'ref'
}

export const SequenceMappedTypes = [
  RecContentItemTypeDto.SHERPATH_LESSON,
  RecContentItemTypeDto.SHERPATH_SIMULATION,
  RecContentItemTypeDto.OSMOSIS_VIDEO,
];

export enum CatalogItemInclude {
  'OSMOSIS_VIDEO' = 'Osmosis Video'
}
