import {
  RecContentItemDto,
} from '../../apis/rec-gateway/rec-gateway.dtos';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { SyllabusItemAction } from '../../constants/content-type.constants';

export interface SyllabusItemWithChildren {
  syllabusItem: SyllabusItemDto;
  children: SyllabusItemWithChildren[];
}

export interface NewSyllabusItemParams {
  courseSectionId: string;
  item: RecContentItemDto;
  parentId: string;
  catalogDto: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
}

export interface NewSyllabusFolderParams {
  courseSectionId: string;
  title: string;
  parentId?: string;
  displayOrder: number;
}

export enum ActionMenuItemType {
  ACTION = 'ACTION',
  DIVIDER = 'DIVIDER',
}

export interface ActionMenuItemConfig {
  action?: SyllabusItemAction;
  type: ActionMenuItemType;
}

export type UnassignBuckets = {
  unassignable: SyllabusItemDto[];
  skippedUnassigned: SyllabusItemDto[];
  skippedStartedAndGraded: SyllabusItemDto[];
};
