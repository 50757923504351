import get from 'lodash/get';
import reduce from 'lodash/reduce';
import moment from 'moment';
import {
  isEmpty,
} from 'lodash';
import { coursewareInitialState } from './courseware/courseware.constants';
import { CoursewareStore } from './courseware/courseware.models';
import {
  omitStateKeys,
  RootState
} from './redux.constants';
import {
  CatalogWithExternalEntitiesDto,
  OsmosisTokenDto
} from '../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS } from '../pages/catalog/catalog.constants';
import { RecContentItemTypeDto } from '../apis/rec-gateway/rec-gateway.dtos';
import { stripNilProps } from '../utilities/common.utilities';
import { CourseSectionDto } from '../apis/eols-course-crud/eols-course-crud.dtos';
import { AssessmentSubmissionDto } from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import institutions from '../constants/institutions.constants.json';

const filterNotFoundError = (errors: Error[]) => errors.filter(error => {
  if (get(error, 'response.status', '') === 404) {
    return false;
  }
  if (get(error, 'error.response.status', '') === 404) {
    return false;
  }
  if (get(error, 'response.statusCode', '') === 404) {
    return false;
  }
  return true;
});

const includesConflictError = (errors: Error[]) => errors.filter(error => {
  if (get(error, 'response.status', '') === 409) {
    return true;
  }
  if (get(error, 'error.response.status', '') === 409) {
    return true;
  }
  if (get(error, 'response.statusCode', '') === 409) {
    return true;
  }
  return false;
});

const omitProps = (rootState: RootState): RootState => {
  const coursewareState = reduce(rootState.coursewareState, (acc, cur, key) => {
    if (omitStateKeys[key]) {
      return acc;
    }
    return {
      ...acc,
      [key]: cur,
    };
  }, {}) as CoursewareStore;
  return {
    ...rootState,
    coursewareState,
  };
};

const omitPropsWithDefaultValue = (rootState: RootState): RootState => {
  const coursewareState = reduce(rootState.coursewareState, (acc, cur, key) => {
    if (coursewareInitialState[key] !== cur) {
      return {
        ...acc,
        [key]: cur,
      };
    }
    return acc;
  }, {}) as CoursewareStore;
  return {
    ...rootState,
    coursewareState,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const addMockPublishAttribute = (catalog: CatalogWithExternalEntitiesDto): CatalogWithExternalEntitiesDto => {

  if (!catalog || !catalog.catalog || !catalog.catalog.data || !catalog.catalog.data.length) {
    return catalog;
  }

  const lessonItems = catalog.catalog.data.filter((contentItem) => {
    return [RecContentItemTypeDto.ADAPTIVE_LESSON, RecContentItemTypeDto.SHERPATH_LESSON].includes(contentItem.type);
  }).sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }).reduce((acc, cur, idx) => {
    if (idx % 10 === 0) {
      return {
        ...acc,
        notPublished: [...acc.notPublished, cur.id]
      };
    }
    if (idx % 11 === 0) {
      return {
        ...acc,
        publishedNew: [...acc.publishedNew, cur.id]
      };
    }
    if (idx % 12 === 0) {
      return {
        ...acc,
        publishedOld: [...acc.publishedOld, cur.id]
      };
    }
    return acc;
  }, {
    publishedOld: [],
    publishedNew: [],
    notPublished: []
  });

  return {
    ...catalog,
    catalog: {
      ...catalog.catalog,
      data: catalog.catalog.data.map((contentItem) => {
        let isPublished;
        let publishDate;
        if (lessonItems.notPublished.includes(contentItem.id)) {
          isPublished = 'false';
        } else if (lessonItems.publishedOld.includes(contentItem.id)) {
          isPublished = 'true';
          publishDate = moment()
            .add(-RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS, 'days')
            .add(-2, 'days')
            .toDate()
            .toISOString();
        } else if (lessonItems.publishedNew.includes(contentItem.id)) {
          isPublished = 'true';
          publishDate = moment()
            .add(-RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS, 'days')
            .add(2, 'days')
            .toDate()
            .toISOString();
        }
        return {
          ...contentItem,
          attributes: stripNilProps({
            ...contentItem.attributes,
            isPublished,
            publishDate
          })
        };
      })
    }
  };
};

const getEvolveResourcesFromCourseSection = (isbns: string[], currentCourse: CourseSectionDto) => {
  if (isEmpty(isbns) || isEmpty(currentCourse) || isEmpty(currentCourse.entitlements)) {
    return [];
  }

  return isbns.reduce((_acc, isbn) => {
    return currentCourse.entitlements.reduce((acc, entitlement) => {
      if (isbn === entitlement.isbn) {
        return [...acc, JSON.parse(entitlement.data)];
      }
      return acc;
    }, _acc);
  }, []);
};

const shouldGetNewOsmosisToken = (osmosisTokenDto: OsmosisTokenDto) => {
  if (!osmosisTokenDto || !osmosisTokenDto.expires) {
    return true;
  }
  return moment.utc(osmosisTokenDto.expires).add({ hours: -10 }).isBefore(moment());
};

const getAssessmentSubmissionsMap = (
  assessmentSubmissionsResponses: {
    assignmentId: string;
    assessmentSubmissions: AssessmentSubmissionDto[];
  }[]
) => {
  if (!assessmentSubmissionsResponses || !assessmentSubmissionsResponses.length) {
    return {};
  }
  return assessmentSubmissionsResponses.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.assignmentId]: cur.assessmentSubmissions
    };
  }, {});
};

const getNormalizedInstitution = (userEmailDomain : string) => {
  let institution = 'Unknown';
  if (!userEmailDomain) {
    return institution;
  }
  const cleanedUserEmailDomain = userEmailDomain.trim().toLowerCase();
  institution = institutions[cleanedUserEmailDomain] || institution;
  return institution;
};

export {
  filterNotFoundError,
  includesConflictError,
  omitProps,
  omitPropsWithDefaultValue,
  addMockPublishAttribute,
  getEvolveResourcesFromCourseSection,
  shouldGetNewOsmosisToken,
  getAssessmentSubmissionsMap,
  getNormalizedInstitution
};
