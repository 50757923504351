import moment from 'moment';
import { isNil } from 'lodash';
import { ELSURLHelper } from '@els/els-ui-common-react';
import { ELSStepperItem } from '@els/els-component-shared-ts-react';
import {
  AssessmentStatusDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { BaseAssignmentEditorState } from './withBaseAssignmentEditor.hoc';
import { AssignmentEditorSearchParam } from './with-base-assignment-editor.constants';
import { isOnlyNumber } from '../../utilities/common.utilities';
import { UserDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  PostSaveAssignment,
  StepSubtitleHandler
} from './with-base-assignment-editor.models';
import { AnalyticsAction } from '../../models/analytics.models';

export const updateStepsOnNav = (steps: ELSStepperItem[], newActiveIndex: number): ELSStepperItem[] => {
  return steps.map((step, idx) => {
    return {
      ...step,
      isActive: idx === newActiveIndex,
      isClickable: idx < newActiveIndex,
      isComplete: idx < newActiveIndex
    };
  });
};

export const getStepFromLocation = (): number => {
  const activeStepIndex = ELSURLHelper.getParameterByName(AssignmentEditorSearchParam.ACTIVE_STEP_INDEX);
  if (isNil(activeStepIndex)) {
    return 0;
  }
  if (!isOnlyNumber(activeStepIndex)) {
    return 0;
  }
  const num = parseInt(activeStepIndex, 10);
  if (isNaN(num)) {
    return 0;
  }
  return num;
};

export const getGoalIdFromVtwId = (
  assignment: Partial<AssignmentDto>,
  vtwId: string
): number => {

  // This method assumes there is a 1on1 relationship between goals and vtwIds

  if (!assignment || !assignment.assignmentGoals) {
    return null;
  }

  const existingGoal = assignment.assignmentGoals.find((goal) => {
    return goal.vtwId === vtwId;
  });

  if (!existingGoal) {
    return null;
  }

  return existingGoal.id;
};

export const getTopicIdFromVtwId = (
  assignment: Partial<AssignmentDto>,
  vtwId: string
): number => {

  // This method assumes there is a 1on1 relationship between goals and vtwIds

  if (!assignment || !assignment.assignmentTopics) {
    return null;
  }

  const existingTopic = assignment.assignmentTopics.find((topic) => {
    return topic.vtwId === vtwId;
  });

  if (!existingTopic) {
    return null;
  }

  return existingTopic.id;
};

export const getDefaultAssignmentEditorState = (): BaseAssignmentEditorState => {
  return {
    activeStepIndex: getStepFromLocation(),
    assignmentId: ELSURLHelper.getParameterByName(AssignmentEditorSearchParam.ASSIGNMENT_ID),
    assignmentType: null,
    assignment: null,
    hasAvailableDate: true,
    hasDueDate: false,
    pageTitle: 'Page Title',
    parentSyllabusItemId: ELSURLHelper.getParameterByName(AssignmentEditorSearchParam.PARENT_SYLLABUS_ITEM_ID),
    showDisplayName: false,
    showDueDate: false,
    showGrading: false,
    steps: [],
    syllabusItemId: ELSURLHelper.getParameterByName(AssignmentEditorSearchParam.SYLLABUS_ITEM_ID),
    isReadOnlyDisplayName: false,
    isUnassignable: false,
    newSyllabusItemType: null,
    existingAssignment: null,
    selectedStudents: [],
    studyMode: false,
    isInitialStateSet: false
  };
};

export const getDefaultDueDateMomentIns = (availableDate?: string | Date): moment.Moment => {
  return moment(availableDate)
    .endOf('day');
};

export const getDefaultDueDateString = (availableDate?: string | Date): string => {
  return getDefaultDueDateMomentIns(availableDate)
    .toISOString();
};

export const getCurrentDateISOString = (): string => {
  return moment()
    .toISOString();
};

export const isStudentOptionDisabled = (props: {
  assignment: AssignmentDto;
  student: UserDto;
}) => {
  const {
    assignment,
    student
  } = props;

  if (!student) {
    return true;
  }

  if (!assignment || !assignment.studentAssignments || !assignment.studentAssignments.length) {
    return false;
  }

  return assignment.studentAssignments.filter((stuAssignment) => {
    return stuAssignment.userId === student.id;
  })
    .some((stuAssignment) => {
      return stuAssignment.status !== AssessmentStatusDto.NOT_STARTED;
    });
};

export const postSaveHandler: PostSaveAssignment = (props) => {
  if (!props.appLinkCookies.linkId) {
    // eslint-disable-next-line no-console
    console.log('Missing link id');
    return;
  }

  const appLinkConfig = {
    linkId: props.appLinkCookies.linkId,
    assignmentIds: props.assignment && props.assignment.id ? [props.assignment.id] : null
  };

  props.trackAction({
    action: AnalyticsAction.ASSIGNMENT_EDITOR_POST_HANDLED,
    props: {
      ref: 'EAQ_ASSIGNMENT_EDITOR',
      assignmentIds: appLinkConfig.assignmentIds
    }
  });

  props.returnAppLink(appLinkConfig);
};

export const defaultStepSubtitleHandler: StepSubtitleHandler = (step) => {
  return step ? step.subtitle : '';
};
