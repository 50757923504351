import {
  AppAction,
  Application
} from '../apis/eols-app-link/eols-app-link.constants';
import { AppLinkRedirectErrorMessage } from '../pages/app-link-redirect/app-link-redirect.constants';

export enum AnalyticsAction {
  LOGIN_LAUNCH = 'LOGIN_LAUNCH',
  APP_LINK_LAUNCH = 'APP_LINK_LAUNCH',
  APP_LINK_ERROR = 'APP_LINK_ERROR',
  APP_LINK_OUT = 'APP_LINK_OUT',
  API_ERROR = 'API_ERROR',
  COURSEWARE_MASTERY_RESET = 'COURSEWARE_MASTERY_RESET',
  ASSIGNMENT_EDITOR_POST_HANDLED = 'ASSIGNMENT_EDITOR_POST_HANDLED',
  EAQ_REPORT_CONTENT_ERROR_CLICKED = 'EAQ_REPORT_CONTENT_ERROR_CLICKED',
}

export type AnalyticsActionProps = {
  action: AnalyticsAction.LOGIN_LAUNCH;
  props: null;
} | {
  action: AnalyticsAction.API_ERROR;
  props: {
    status: string;
    action: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_ERROR;
  props: {
    type: AppLinkRedirectErrorMessage;
    status: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_OUT
    | AnalyticsAction.APP_LINK_LAUNCH;
  props: {
    linkType: AppAction;
    srcApp: Application;
    targetApp: Application;
  };
} | {
  action: AnalyticsAction.COURSEWARE_MASTERY_RESET;
  props: {
    resetIsbn: string;
    studentUserId: number;
  };
} | {
  action: AnalyticsAction.ASSIGNMENT_EDITOR_POST_HANDLED;
  props: {
    ref: string;
    assignmentIds: number[];
  };
} | {
  action: AnalyticsAction.EAQ_REPORT_CONTENT_ERROR_CLICKED;
  props: {
    questionVtwId: string;
    isbn: string;
    ref: string;
  };
}
