import {
  get,
  isEmpty,
  isNil,
  keyBy,
  orderBy,
  partition,
  times,
  uniq,
  uniqBy
} from 'lodash';
import { decode } from 'html-entities';
import React from 'react';
import {
  RecContentItemDto,
  RecContentItemTypeDto,
  RecJsonApiDataItem,
  RecTaxonomyNodeAttributesNodeTypeDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import {
  ALL_OPTION_NAME,
  ALL_OPTION_VALUE,
  CatalogItemInclude,
  EbookPageRangeSeparators,
  SequenceMappedTypes,
  SyllabusFolderOptionType
} from './catalog.constants';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { ActiveSyllabusItemTypeDto, } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { getSortedSyllabusTreeMapItems } from '../course-builder/courseBuilder.utilities';
import {
  ELSCommonConfig,
  ELSDropDownOption,
  ELSLoggingService
} from '../../components/els.components';
import {
  AppAction,
  Application,
} from '../../apis/eols-app-link/eols-app-link.constants';
import { RoutePath } from '../../components/app/app.constants';
import {
  addSearchParams,
  SELECT_OPTION
} from '../../utilities/app.utilities';
import {
  CatalogAdaptiveLessonExternalEntityDto,
  CatalogAdaptiveQuizExternalEntityDtoParsed,
  CatalogEvolveResourceExternalEntityDtoParsed,
  CatalogEvolveResourcePermission,
  CatalogExternalEntityDtoParsed,
  CatalogOsmosisVideoExternalEntityDtoParsed,
  CatalogShadowHealthExternalEntityDtoParsed,
  CatalogSherpathCaseStudyExternalEntityDtoParsed,
  CatalogSherpathGroupActivityExternalEntityDtoParsed,
  CatalogSherpathLessonExternalEntityDtoParsed,
  CatalogSherpathPowerpointExternalEntityDtoParsed,
  CatalogSherpathSimulationExternalEntityDtoParsed,
  CatalogSherpathSkillExternalEntityDtoParsed,
  CatalogSimChartExternalEntityDtoParsed,
  CatalogWithExternalEntitiesDto,
  OsmosisTokenDto,
  UserDto,
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  filterBySearchQuery,
  flattenTree,
  isInstructor,
  isStudent,
  mapToIds,
  stripNilProps,
  truncateTitle
} from '../../utilities/common.utilities';
import { AssignmentEditorSearchParam } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.constants';
import {
  BundleMemberProductDto,
  BundleMemberProductTypeKey,
  EvolveProductDto,
  EvolveProductType,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import {
  FALSE_VALUE,
  SHER_EVOL_FEATURE_FLAG,
  TRUE_VALUE
} from '../../apis/eols-features-api/eols-features-api.constants';
import {
  getEvolveProductsForAction,
  getIsbnFromEbookCatalogItemId,
  getPageRangesFromEbookCatalogItemId,
  getTaxonsTitle,
} from '../course-plan/syllabus.utilities';
import {
  PrimaryTaxonomy,
  PrimaryTaxonomyDto,
  PrimaryTaxonomyTaxonDto,
} from '../../apis/rec-gateway/rec-gateway.models';
import {
  getBookTaxonomy,
  getChapterPageRangesFromPageRanges,
  getEbookAssignmentTitle,
  getEbookComponents,
  getPageCountFromPageRanges,
} from '../ebook-assignment-editor/ebook-assignment.utilities';
import { ELSModalServiceType } from '../../models/els.models';
import {
  CatalogResourceStatus,
  CatalogResourceStatusMap,
  LessonSyllabusItemTypes
} from '../course-plan/syllabus.constants';
import { SyllabusTreeMapItem } from '../course-builder/courseBuilder.models';
import { AssignmentDto, } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { LocationWithQuery } from '../../redux/location/location.models';
import {
  CatalogItemConfig,
  CatalogItemConfigMap,
  ResourceStatusMap
} from './catalog.models';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import {
  SyllabusItemAction,
  SyllabusItemTypeConfigMap,
  UnSupportedEvolveResourceTypeDEPRECATED,
} from '../../constants/content-type.constants';
import { SyllabusTypeFilterMap } from '../../models/common.models';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { ServerConstants } from '../../components/app/server.constants';
import { NavigateToApp } from '../../redux/courseware/courseware.models';
import {
  ItemSequenceMap,
  SequenceItemDto,
  SequenceMap
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { getHourAndMinuteFromSecond } from '../../utilities/unitConverter.utilities';

const fileName = 'catalog.utilities';

const contentBaseURL = ServerConstants[ELSCommonConfig.appProfile].contentBaseURL as string;

export const getEbookNodeTitle = (node: Partial<PrimaryTaxonomyTaxonDto> | RecTaxonomyNodeDto) => {
  if (!node || !node.attributes) {
    return '';
  }
  return node.attributes.displayName || node.attributes.text || '';
};

export const getEbookTitle = (bookTaxonomy: PrimaryTaxonomyDto): string => {
  if (!bookTaxonomy || !bookTaxonomy.data || !bookTaxonomy.data[0]) {
    return '';
  }
  return getEbookNodeTitle(bookTaxonomy.data[0]);
};

export const getVbId = (evolveProduct: EvolveProductDto) => {
  if (!evolveProduct) {
    return null;
  }
  return evolveProduct.vbId || evolveProduct.vbID;
};

export const getTaxonomiesWithFlattenedBranchChildren = (
  fullList: RecTaxonomyNodeDto[],
  ids: string[],
  level = 1
): RecTaxonomyNodeDto[] => {
  return fullList
    .filter(x => {
      return ids.includes(x.id);
    })
    .sort((a, b) => {
      return a.attributes.displayOrder - b.attributes.displayOrder;
    })
    .reduce((acc, cur) => {
      if (get(cur, 'relationships.children.data.length')) {
        const childIds = cur.relationships.children.data.map(mapToIds);
        return [...acc, cur, ...getTaxonomiesWithFlattenedBranchChildren(fullList, childIds, level + 1)];
      }
      return [...acc, cur];
    }, []);
};

export const getTaxonomyFromIds = (catalog: CatalogWithExternalEntitiesDto, ids: string[]): RecTaxonomyNodeDto[] => {
  return catalog.catalog.included.filter((taxonomy) => ids.includes(taxonomy.id));
};

export const filterContentItemsByTaxonomyNodes = (contentItems: RecContentItemDto[], props: {
  catalog: CatalogWithExternalEntitiesDto;
  taxonomyIds: string[];
}): RecContentItemDto[] => {

  const {
    catalog,
    taxonomyIds
  } = props;

  if (!contentItems || !contentItems.length || !taxonomyIds || !taxonomyIds.length) {
    return contentItems;
  }

  const nodeIds = getTaxonomiesWithFlattenedBranchChildren(
    catalog.catalog.included,
    taxonomyIds
  ).map(mapToIds);
  return contentItems.filter((item) => {
    return item.relationships.taxonomies.data.find((relationship) => {
      return nodeIds.includes(relationship.id);
    });
  });
};

export const getContentItemsByTaxonomies = (catalog: CatalogWithExternalEntitiesDto, taxonomies: RecTaxonomyNodeDto[]) => {

  if (!taxonomies || !taxonomies.length) {
    return [];
  }

  return filterContentItemsByTaxonomyNodes(catalog.catalog.data, {
    catalog,
    taxonomyIds: taxonomies.map(mapToIds)
  });
};

export const getContentItemsByTaxonomyNodes = (
  catalog: CatalogWithExternalEntitiesDto,
  taxonomyIds: string[]
): RecContentItemDto[] => {
  if (!taxonomyIds) {
    return [];
  }
  const taxonomies = getTaxonomyFromIds(catalog, taxonomyIds);
  if (!taxonomies) {
    return [];
  }
  return getContentItemsByTaxonomies(catalog, taxonomies);
};

export const isSelectAll = (
  contentItems: RecContentItemDto[],
  checkedContentItemsMap: object
) => {
  const uncheckedItem = contentItems.find((contentItem: RecContentItemDto) => !checkedContentItemsMap[contentItem.id]);
  return !uncheckedItem;
};

export const getExternalEntity = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogExternalEntityDtoParsed => {

  if (!contentItem || !catalog.externalEntities) {
    return null;
  }

  const externalEntity = catalog.externalEntities.find((_externalEntity) => {
    return _externalEntity.catalogItemId === contentItem.id;
  });

  if (!externalEntity || !externalEntity.entity) {
    return null;
  }

  if ([
    RecContentItemTypeDto.SHERPATH_LESSON,
    RecContentItemTypeDto.EVOLVE_RESOURCE,
    RecContentItemTypeDto.SHADOW_HEALTH,
    RecContentItemTypeDto.SHERPATH_SIMULATION,
    RecContentItemTypeDto.SHERPATH_SKILL,
    RecContentItemTypeDto.SHERPATH_POWERPOINT,
    RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY,
    RecContentItemTypeDto.SHERPATH_CASE_STUDY,
    RecContentItemTypeDto.OSMOSIS_VIDEO,
  ].includes(contentItem.type)) {

    let _parsedData;

    const _externalEntity = externalEntity as
      CatalogSherpathLessonExternalEntityDtoParsed
      | CatalogEvolveResourceExternalEntityDtoParsed
      | CatalogShadowHealthExternalEntityDtoParsed
      | CatalogSherpathSimulationExternalEntityDtoParsed
      | CatalogSherpathSkillExternalEntityDtoParsed
      | CatalogSherpathCaseStudyExternalEntityDtoParsed
      | CatalogSherpathPowerpointExternalEntityDtoParsed
      | CatalogSherpathGroupActivityExternalEntityDtoParsed
      | CatalogOsmosisVideoExternalEntityDtoParsed;

    try {
      _parsedData = JSON.parse(_externalEntity.entity.data);
    } catch (e) {
      _parsedData = {
        // Amazingly this fallback works because all these external entity types share a title property
        title: `${contentItem.type} - ${contentItem.id}`
      };
      ELSLoggingService.error(
        fileName,
        `Unable to parse external entity '${contentItem.id}`,
      );
    }

    return {
      ..._externalEntity,
      _parsedData
    };
  }

  if (contentItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
    return externalEntity as CatalogAdaptiveLessonExternalEntityDto;
  }

  if (contentItem.type === RecContentItemTypeDto.SIM_CHART) {
    let _parsedData;
    const _externalEntity = externalEntity as CatalogSimChartExternalEntityDtoParsed;

    try {
      _parsedData = JSON.parse(_externalEntity.entity);
    } catch (e) {
      _parsedData = {
        // Amazingly this fallback works because all these external entity types share a title property
        title: `${contentItem.type} - ${contentItem.id}`
      };
      ELSLoggingService.error(
        fileName,
        `Unable to parse external entity '${contentItem.id}`,
      );
    }

    return {
      ..._externalEntity,
      _parsedData
    };

  }

  return null;

};

export const getEvolveResource = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogEvolveResourceExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogEvolveResourceExternalEntityDtoParsed;
};

export const getSherpathGroupActivity = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathGroupActivityExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathGroupActivityExternalEntityDtoParsed;
};

export const getSherpathPowerpoint = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathPowerpointExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_POWERPOINT) {
    return null;
  }

  const externalEntity = getExternalEntity(contentItem, catalog) as CatalogSherpathPowerpointExternalEntityDtoParsed;
  const { _parsedData } = externalEntity;
  const fullURL = new URL(_parsedData.url, contentBaseURL).href;
  return {
    ...externalEntity,
    _parsedData: {
      ..._parsedData,
      _fullURL: fullURL,
    }
  };
};

export const getSherpathCaseStudy = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathCaseStudyExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_CASE_STUDY) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathCaseStudyExternalEntityDtoParsed;
};

export const getShadowHealth = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogShadowHealthExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHADOW_HEALTH) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogShadowHealthExternalEntityDtoParsed;
};

export const getOsmosisVideo = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogOsmosisVideoExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.OSMOSIS_VIDEO) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogOsmosisVideoExternalEntityDtoParsed;
};

export const isEvolveResourceSupported = (evolveResource: CatalogEvolveResourceExternalEntityDtoParsed, unSupportedEvolveResourceTypes: string[] = []): boolean => {
  if (!evolveResource || !evolveResource._parsedData) {
    return true;
  }

  // Fall back to the old way so as not to break production courses that do not yet have new assetType props
  if (!evolveResource._parsedData.assetType && evolveResource._parsedData.type) {
    const normalizedType = evolveResource._parsedData.type.trim().toLowerCase();
    const unSupportedEvolveResourceTypesDEPRECATED = [
      UnSupportedEvolveResourceTypeDEPRECATED.LMS.toLowerCase(),
      UnSupportedEvolveResourceTypeDEPRECATED.DOWNLOAD_BY_RESOURCE_TYPE.toLowerCase(),
      UnSupportedEvolveResourceTypeDEPRECATED.DOWNLOADS.toLowerCase(),
    ];
    return !unSupportedEvolveResourceTypesDEPRECATED.includes(normalizedType);
  }

  if (!unSupportedEvolveResourceTypes || !unSupportedEvolveResourceTypes.length || !evolveResource._parsedData.assetType) {
    return true;
  }

  const normalizedType = evolveResource._parsedData.assetType.trim();
  return !unSupportedEvolveResourceTypes.includes(normalizedType);
};

export const getContentItemsByStatus = (statusFilterState: Array<string>, contentItems: RecContentItemDto[], resourceStatusMap: ResourceStatusMap): RecContentItemDto[] => {
  return statusFilterState.reduce((acc, cur) => {
    let filteredContentItemsByStatus;
    if (cur === CatalogResourceStatusMap[CatalogResourceStatus.NOT_ADDED].value) {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        return !resourceStatusMap[contentItem.id];
      });
    } else if (cur === CatalogResourceStatusMap[CatalogResourceStatus.VISIBLE].value) {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        const visibleResources = resourceStatusMap[contentItem.id] && resourceStatusMap[contentItem.id][cur].length !== 0;
        const dueResources = resourceStatusMap[contentItem.id] && resourceStatusMap[contentItem.id].assigned.length !== 0;
        return visibleResources || dueResources;
      });
    } else if (cur === CatalogResourceStatusMap[CatalogResourceStatus.DUE].value) {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        return resourceStatusMap[contentItem.id] && resourceStatusMap[contentItem.id].assigned.length !== 0;
      });
    } else {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        return resourceStatusMap[contentItem.id]
          && resourceStatusMap[contentItem.id][cur]
          && resourceStatusMap[contentItem.id][cur].length !== 0;
      });
    }
    return filteredContentItemsByStatus.length !== 0 ? acc.concat(filteredContentItemsByStatus) : acc;
  }, []);
};

export const getNumberOfHiddenCheckedItem = (checkedItemIds: Array<string>, filteredItems: Array<RecContentItemDto | SyllabusItemDto>): number => {
  const hiddenCheckedSyllabusItems = checkedItemIds.filter(checkedItemId =>
    filteredItems.some(filteredItem => filteredItem.id === checkedItemId));

  return checkedItemIds.length - hiddenCheckedSyllabusItems.length;
};

export const getConfirmModalOnHiddenItem = ({ modalService, modalId, numberOfHiddenSyllabus, continueAction, messages, intl }) => {
  return modalService.openConfirmModal({
    modalId,
    content: (
      <>
        <h3>{messages.ARE_YOU_SURE}</h3>
        <p>
          {intl.formatMessage({
            id: LANGUAGE_KEY.CONFIRM_ACTION_ON_HIDDEN_SYLLABUS,
            defaultMessage: messages.CONFIRM_ACTION_ON_HIDDEN_SYLLABUS,
            description: ''
          }, { numberOfHiddenSyllabus })}
        </p>
      </>
    ),
    confirmHandler: async () => {
      await modalService.closeModal(modalId);
      return continueAction();
    },
    cancelHandler: () => modalService.closeModal(modalId)
  });
};

export const getTitleWithLevelDashes = (level, levelOffset = 0, title): string => {
  let dashes = '';
  times(level - levelOffset, () => {
    dashes += '-';
  });
  return `${dashes}${title}`;
};

export const getTaxonomyRootNodes = (catalog: CatalogWithExternalEntitiesDto): RecTaxonomyNodeDto[] => {
  return catalog.catalog.included.filter((taxonomy) => {
    return taxonomy.attributes.root === true;
  });
};

export const getTaxonomyBookNodes = (catalog: CatalogWithExternalEntitiesDto): RecTaxonomyNodeDto[] => {
  return catalog.catalog.included.filter((taxonomy) => {
    return taxonomy.attributes.nodeType === RecTaxonomyNodeAttributesNodeTypeDto.BOOK;
  });
};

export const getProductFilters = (evolveProducts: EvolveProductDto[]): ELSDropDownOption[] => {

  const productOptions = uniqBy(evolveProducts, x => x.isbn).map((evolveProduct): ELSDropDownOption => {
    return {
      name: `${evolveProduct.title} (ISBN: ${evolveProduct.isbn})`,
      value: evolveProduct.isbn
    };
  }).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const allOption: ELSDropDownOption = {
    name: ALL_OPTION_NAME,
    value: ALL_OPTION_VALUE
  };

  productOptions.unshift(allOption);

  return productOptions;
};

export const getBookSectionFilters = (catalog: CatalogWithExternalEntitiesDto): ELSDropDownOption[] => {
  const bookTaxonomies: RecTaxonomyNodeDto[] = getTaxonomyBookNodes(catalog);
  const sectionFilterOptions = getTaxonomiesWithFlattenedBranchChildren(
    catalog.catalog.included,
    bookTaxonomies.map(x => x.id)
  ).map((item: RecTaxonomyNodeDto) => ({
    name: `${getTitleWithLevelDashes(item.attributes.level, 0, getEbookNodeTitle(item))}`,
    value: item.id
  }));
  return [{
    name: ALL_OPTION_NAME,
    value: ALL_OPTION_VALUE
  }].concat(sectionFilterOptions);
};

export const getSyllabusFoldersOptionList = (syllabusItems: SyllabusItemDto[]): ELSDropDownOption[] => {
  const folderOptionList = [SyllabusFolderOptionType.SELECT_FOLDER];
  const sortedSyllabusTreeMapItems = getSortedSyllabusTreeMapItems(syllabusItems);
  const levelIndicator = '– ';
  const folderOptions = sortedSyllabusTreeMapItems.filter(treeMapItem => treeMapItem.syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER);
  folderOptions.forEach(treeMapItem => {
    const { syllabusItem: { id, title }, level } = treeMapItem;
    return folderOptionList.push({
      value: id,
      name: `${levelIndicator.repeat(level)}${title}`,
    });
  });
  folderOptionList.push(SyllabusFolderOptionType.ADD_FOLDER);
  return folderOptionList;
};

export const getTimeEstimateInMinuteFromPageCount = (pageCount: number) => {
  return pageCount * 3 * 60;
};

export const getTimeEstimateInSecondFromPageRanges = (pageRanges: string) => {
  const pageCount = getPageCountFromPageRanges(pageRanges);
  return getTimeEstimateInMinuteFromPageCount(pageCount);
};

export const getTimeEstimateDisplayFromPageRanges = (pageRanges: string) => {
  const pageCount = getPageCountFromPageRanges(pageRanges);
  return `(${pageCount} ${pageCount === 1 ? 'page' : 'pages'}, est. ${getHourAndMinuteFromSecond(getTimeEstimateInMinuteFromPageCount(pageCount))})`;
};

export const getEbookReadingTitle = (contentItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]): string => {
  const pageRanges = getPageRangesFromEbookCatalogItemId(contentItem.id);
  const isbn = getIsbnFromEbookCatalogItemId(contentItem.id);
  const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
  if (!bookTaxonomy) {
    return null;
  }
  const chapterPageRangeMap = getChapterPageRangesFromPageRanges(pageRanges, bookTaxonomy);

  return getEbookAssignmentTitle(chapterPageRangeMap, bookTaxonomy, false);
};

export const getEbookReadingSubtitle = (contentItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]): string => {
  const isbn = getIsbnFromEbookCatalogItemId(contentItem.id);
  const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
  if (!bookTaxonomy) {
    return null;
  }
  return getEbookTitle(bookTaxonomy);
};

export const getAdaptiveLesson = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogAdaptiveLessonExternalEntityDto => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.ADAPTIVE_LESSON) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogAdaptiveLessonExternalEntityDto;
};

export const getEvolveResourceType = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): ActiveSyllabusItemTypeDto => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return null;
  }
  const evolveResource = getEvolveResource(contentItem, catalog);
  if (
    evolveResource
    && evolveResource._parsedData
    && evolveResource._parsedData.permission
    && evolveResource._parsedData.permission.trim().toLowerCase() === CatalogEvolveResourcePermission.STUDENT
  ) {
    return ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE;
  }
  // Temporarily default to instructor type since students will not have external entity for instructor resources
  // This can be cleaned up once REC services send more specific types for Evolve Resources
  return ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE;
};

const recContentItemTypeDtoToContentTypeMap: Record<RecContentItemTypeDto, ActiveSyllabusItemTypeDto> = {
  [RecContentItemTypeDto.ADAPTIVE_QUIZ]: ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ,
  [RecContentItemTypeDto.ADAPTIVE_LESSON]: ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON,
  [RecContentItemTypeDto.EBOOK_READING]: ActiveSyllabusItemTypeDto.EBOOK_READING,
  [RecContentItemTypeDto.SIM_CHART]: ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY, // All Catalog SimCharts are case studies for now
  [RecContentItemTypeDto.SHERPATH_SKILL]: ActiveSyllabusItemTypeDto.SHERPATH_SKILL,
  [RecContentItemTypeDto.SHERPATH_SIMULATION]: ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS,
  [RecContentItemTypeDto.SHERPATH_LESSON]: ActiveSyllabusItemTypeDto.SHERPATH_LESSON,
  [RecContentItemTypeDto.SHADOW_HEALTH]: ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT,
  [RecContentItemTypeDto.SHERPATH_POWERPOINT]: ActiveSyllabusItemTypeDto.SHERPATH_POWERPOINT,
  [RecContentItemTypeDto.SHERPATH_CASE_STUDY]: ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY,
  [RecContentItemTypeDto.OSMOSIS_VIDEO]: ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO,
  [RecContentItemTypeDto.EVOLVE_RESOURCE]: null,
  [RecContentItemTypeDto.QUESTION]: null, // Not an assignable entity
  [RecContentItemTypeDto.HESI_EXAM]: null,
  [RecContentItemTypeDto.SHERPATH_MODULE]: null,
  [RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY]: ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY,
  [RecContentItemTypeDto.OSMOSIS_VIDEO]: ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO,
  [RecContentItemTypeDto.SHERPATH_TESTBANK]: null,
};

export const getSyllabusItemTypeFromCatalogItem = (recContentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): ActiveSyllabusItemTypeDto => {

  if (!recContentItem) {
    ELSLoggingService.warn(fileName, 'recContentItem not found');
    return null;
  }

  if (recContentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return getEvolveResourceType(recContentItem, catalog);
  }

  return recContentItemTypeDtoToContentTypeMap[recContentItem.type];
};

export const getActiveSyllabusItemTypeFromCatalog = (catalog: CatalogWithExternalEntitiesDto, userRole: string): ActiveSyllabusItemTypeDto[] => {
  return catalog.catalog.data.map((recContentItemDto) => {
    return getSyllabusItemTypeFromCatalogItem(recContentItemDto, catalog);
  }).filter((type) => {
    if (!type) {
      return false;
    }
    if (isInstructor(userRole)) {
      return true;
    }
    return isStudent(userRole) && (type === ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE || type === ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO);
  });
};

export const getTypeOptionsFromSyllabusItemsTypes = (syllabusItemTypes: ActiveSyllabusItemTypeDto[]): SyllabusTypeFilterMap => {
  const options = uniq(syllabusItemTypes)
    .map((type) => {
      return {
        display: SyllabusItemTypeConfigMap[type] ? SyllabusItemTypeConfigMap[type].displayName : type,
        value: type
      };
    });

  return orderBy(options, [
    (option) => {
      const config = SyllabusItemTypeConfigMap[option.value];
      return config ? config.sortOrder : 0;
    }, (option) => {
      return option.display.toLowerCase();
    }
  ])
    .reduce((acc, item) => {
      return {
        ...acc,
        [item.value]: item
      };
    }, {});
};

export const getTypeOptionsForCatalog = (
  catalog: CatalogWithExternalEntitiesDto,
  userRole: string,
): SyllabusTypeFilterMap => {
  const allSyllabusItemTypes = getActiveSyllabusItemTypeFromCatalog(catalog, userRole);
  return getTypeOptionsFromSyllabusItemsTypes(allSyllabusItemTypes);
};

export const isTypeInSelectedTypes = (selectedTypes: ActiveSyllabusItemTypeDto[], syllabusItemType: ActiveSyllabusItemTypeDto): boolean => {

  if (!selectedTypes || !selectedTypes.length || !syllabusItemType) {
    return false;
  }

  if (LessonSyllabusItemTypes.includes(syllabusItemType)) {
    return selectedTypes.some((selectedType) => {
      return LessonSyllabusItemTypes.includes(selectedType);
    });
  }

  return selectedTypes.includes(syllabusItemType);
};

export const getContentItemsByTypes = (
  selectedTypes: ActiveSyllabusItemTypeDto[],
  contentItems: RecContentItemDto[],
  catalog: CatalogWithExternalEntitiesDto
): RecContentItemDto[] => {

  if (!selectedTypes || !selectedTypes.length) {
    return contentItems;
  }

  return contentItems.filter((contentItem) => {
    return isTypeInSelectedTypes(
      selectedTypes,
      getSyllabusItemTypeFromCatalogItem(contentItem, catalog)
    );
  });
};

export const byUserRole = (userRole: string, catalog: CatalogWithExternalEntitiesDto) => (contentItemDto: RecContentItemDto) => {

  if (isInstructor(userRole)) {
    return true;
  }

  // Currently only student resources are supported in the student catalog
  if ((contentItemDto.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) && (contentItemDto.type !== RecContentItemTypeDto.OSMOSIS_VIDEO)) {
    return false;
  }

  const type = getSyllabusItemTypeFromCatalogItem(contentItemDto, catalog);
  return (type === ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE) || (type === ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO);
};

const convertContentItemForSearchBox = (catalogItemConfigMap: CatalogItemConfigMap, contentItem: RecContentItemDto): string[] => {
  if (!catalogItemConfigMap) {
    return [];
  }
  const itemConfigMap = catalogItemConfigMap[contentItem.id];
  if (isNil(itemConfigMap)) {
    return [];
  }

  let itemConfigMapString = '';
  if (!isNil(itemConfigMap.title)) {
    itemConfigMapString = itemConfigMapString.concat(itemConfigMap.title).concat(' ');
  }
  if (!isNil(itemConfigMap.subtitle)) {
    itemConfigMapString = itemConfigMapString.concat(itemConfigMap.subtitle).concat(' ');
  }
  if (!isNil(itemConfigMap.includes) && itemConfigMap.includes.length > 0) {
    itemConfigMapString = itemConfigMapString.concat(itemConfigMap.includes.join(' ')).concat(' ');
  }
  if (!isNil(itemConfigMap.type) && !isNil(SyllabusItemTypeConfigMap[itemConfigMap.type])) {
    itemConfigMapString = itemConfigMapString.concat(SyllabusItemTypeConfigMap[itemConfigMap.type].displayName);
  }

  return itemConfigMapString.toLowerCase().split(' ').map(item => item.trim());
};

export const getContentItemsBySearchResources = (
  catalogItemConfigMap: CatalogItemConfigMap,
  contentItems: RecContentItemDto[],
  searchQuery: string
): RecContentItemDto[] => {
  if (!catalogItemConfigMap || searchQuery === null || searchQuery === '') {
    return contentItems;
  }
  return contentItems.filter(contentItem => {
    const contentItemWords = convertContentItemForSearchBox(catalogItemConfigMap, contentItem);
    return filterBySearchQuery(searchQuery, contentItemWords);
  });
};

const findTaxon = (itemChild: RecJsonApiDataItem, catalog: CatalogWithExternalEntitiesDto) => catalog.catalog.included.find(taxon => taxon.id === itemChild.id);

const getChildDisplayOrder = (itemChild: RecJsonApiDataItem, catalog: CatalogWithExternalEntitiesDto) => {
  const found = findTaxon(itemChild, catalog);
  return found ? found.attributes.displayOrder : 0;
};

const getItems = (taxon: RecTaxonomyNodeDto, items: RecContentItemDto[]): [RecContentItemDto[], RecContentItemDto[]] => {
  return partition(items, (item) => {
    return item.relationships.taxonomies.data.find(x => x.id === taxon.id);
  });
};

export const getSherpathLessonSequenceMap = (
  moduleSequenceMap: SequenceMap,
  dominantTaxon: RecTaxonomyNodeDto,
): Record<string, SequenceItemDto> => {

  if (!moduleSequenceMap) {
    return null;
  }

  const mapValues = Object.values(moduleSequenceMap);

  if (!mapValues || !mapValues.length) {
    return null;
  }

  // We sort here because in the event that a multi taxon mapped item
  // is being sorted we want to default to the first module sequence value
  const { sortedSequences, dominantModuleVtwId } = mapValues.filter((item) => {
    return item && item.sequenceDto && item.taxon;
  }).sort((a, b) => {
    return a.taxon.attributes.displayOrder - b.taxon.attributes.displayOrder;
  }).reduce((acc, cur) => {
    return {
      sortedSequences: [...acc.sortedSequences, cur.sequenceDto],
      dominantModuleVtwId: dominantTaxon && cur.taxon.id === dominantTaxon.id
        ? cur.sequenceDto.resourceVtwId
        : acc.dominantModuleVtwId
    };
  }, {
    sortedSequences: [],
    dominantModuleVtwId: null
  });

  return sortedSequences.reduce((acc, cur) => {
    if (!cur || !cur.sequence) {
      return acc;
    }
    return cur.sequence.reduce((_acc, _cur) => {
      // Again here when an item maps to multiple modules we leave the first
      // Unless a subsequence modules is the dominant module (selected for this sort context)
      if (!_acc[_cur.vtwId] || (dominantModuleVtwId && cur.resourceVtwId === dominantModuleVtwId)) {
        return {
          ..._acc,
          [_cur.vtwId]: _cur
        };
      }
      return _acc;
    }, acc);
  }, {});
};

const RecContentTypeSortOrder: RecContentItemTypeDto[] = Object.values(SyllabusItemTypeConfigMap)
  .filter((item) => {
    return item.recContentType;
  })
  .sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  })
  .reduce((acc, item) => {
    if (acc.indexOf(item.recContentType) === -1) {
      return [...acc, item.recContentType];
    }
    return acc;
  }, []);

const evolveResourceTypeSortMap = [
  CatalogEvolveResourcePermission.INSTRUCTOR,
  CatalogEvolveResourcePermission.STUDENT,
];

export const sortCatalogItemsWithoutTaxonomy = (config: {
  items: RecContentItemDto[];
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  itemSequenceMap: ItemSequenceMap;
}) => {

  //  DominantTaxon is here in case items are mapped to different taxons having different sort orders within each taxon mapping.
  //  In this case we need to choose a single sort order because in this function we do not duplicate content items for each taxon mapping.
  //  In the event dominantTaxon is null we will choose the first tax mapping for an item as its dominantTaxon.

  const {
    items,
    catalog,
    catalogItemConfigMap,
    itemSequenceMap,
  } = config;

  return orderBy(items, [
    (contentItem) => {
      return RecContentTypeSortOrder.indexOf(contentItem.type);
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (contentItem) => {

      if (SequenceMappedTypes.includes(contentItem.type)) {
        if (!itemSequenceMap || !contentItem.attributes || !contentItem.attributes.contentId) {
          return null;
        }
        const sequenceItem = itemSequenceMap[contentItem.attributes.contentId];
        if (!sequenceItem) {
          return null;
        }
        return sequenceItem.sequenceIndex;
      }

      if (contentItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
        const adaptiveLesson = getAdaptiveLesson(contentItem, catalog);
        if (!adaptiveLesson) {
          return null;
        }
        return adaptiveLesson.entity.attributes.displayOrder;
      }

      if (contentItem.type === RecContentItemTypeDto.SHADOW_HEALTH) {
        const shadowHealth = getShadowHealth(contentItem, catalog);
        if (!shadowHealth) {
          return null;
        }
        return shadowHealth._parsedData.order;
      }

      if (contentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
        const evolveResource = getEvolveResource(contentItem, catalog);
        if (!evolveResource) {
          return null;
        }
        return evolveResourceTypeSortMap.indexOf(evolveResource._parsedData.permission.toLowerCase() as CatalogEvolveResourcePermission);
      }

      return null;
    },
    (contentItem) => {
      if (!catalogItemConfigMap || !catalogItemConfigMap[contentItem.id]) {
        return contentItem.id;
      }
      return catalogItemConfigMap[contentItem.id].title;
    },
  ]);
};

const getOrderedSectionWithItems = (props: {
  nextTaxon: RecTaxonomyNodeDto;
  items: RecContentItemDto[];
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  moduleSequenceMap: SequenceMap;
  dominantTaxon: RecTaxonomyNodeDto;
}): {
  taxon: RecTaxonomyNodeDto;
  items: RecContentItemTypeDto[];
}[] => {

  // Note we do not want to filter out duplicate mappings in this function
  // Duplicate mappings should be handled upstream

  const {
    nextTaxon,
    items,
    catalog,
    catalogItemConfigMap,
    moduleSequenceMap,
    dominantTaxon
  } = props;

  if (!nextTaxon) {
    return [];
  }

  const taxonBranchRoot = dominantTaxon || nextTaxon;
  const branchTaxonIds = getTaxonomiesWithFlattenedBranchChildren(catalog.catalog.included, [taxonBranchRoot.id]).map(mapToIds);

  return nextTaxon.relationships.children.data.sort((a, b) => {
    return getChildDisplayOrder(a, catalog) - getChildDisplayOrder(b, catalog);
  }).reduce((acc, cur) => {
    const taxon = findTaxon(cur, catalog);

    if (!branchTaxonIds.includes(taxon.id)) {
      // Here we make sure to only return items in a certain section of the taxonomy tree
      return acc;
    }

    const parts = getItems(taxon, items);

    const itemSequenceMap = getSherpathLessonSequenceMap(moduleSequenceMap, dominantTaxon);

    // Note: This sort is slightly expensive
    // Currently I do not see any way to avoid it
    const sortedItems = sortCatalogItemsWithoutTaxonomy({
      items: parts[0],
      catalog,
      catalogItemConfigMap,
      itemSequenceMap,
    });

    const section = {
      taxon,
      items: sortedItems,
    };

    if (taxon.relationships.children && taxon.relationships.children.data.length) {
      return [...acc, section, ...getOrderedSectionWithItems({
        ...props,
        nextTaxon: taxon
      })];
    }
    return [...acc, section];
  }, []);
};

export const getUniqueSortedPrimaryTaxonomies = (primaryTaxonomies: PrimaryTaxonomy[]): PrimaryTaxonomy[] => {
  if (!primaryTaxonomies || !primaryTaxonomies.length) {
    return primaryTaxonomies;
  }

  const uniqPrimaryTaxonomies = uniqBy(primaryTaxonomies, (primaryTaxonomy: PrimaryTaxonomy) => {
    return primaryTaxonomy.taxonomy.data[0].id;
  });

  return orderBy(uniqPrimaryTaxonomies, (primaryTaxonomy: PrimaryTaxonomy) => {
    return getEbookTitle(primaryTaxonomy.taxonomy);
  });
};

export const sortCatalogItems = (config: {
  items: RecContentItemDto[];
  sortedTaxonomyIds: string[];
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  moduleSequenceMap: SequenceMap;
  dominantTaxon: RecTaxonomyNodeDto;
}): {
  sectionedItems: RecContentItemDto[];
  flattenedItems: RecContentItemDto[];
  remainingItems: RecContentItemDto[];
} => {

  const {
    items,
    catalog,
    catalogItemConfigMap,
    sortedTaxonomyIds,
    moduleSequenceMap,
    dominantTaxon
  } = config;

  const itemSequenceMap = getSherpathLessonSequenceMap(moduleSequenceMap, dominantTaxon);

  const sortedItemsWithoutTaxonomyChunking = sortCatalogItemsWithoutTaxonomy({
    items,
    catalog,
    catalogItemConfigMap,
    itemSequenceMap
  });

  if (!sortedTaxonomyIds || !sortedTaxonomyIds.length) {
    return {
      flattenedItems: sortedItemsWithoutTaxonomyChunking,
      sectionedItems: sortedItemsWithoutTaxonomyChunking,
      remainingItems: [],
    };
  }

  return sortedTaxonomyIds.reduce((acc, cur, idx, arr) => {

    const taxonomyRootNode = catalog.catalog.included.find((taxon) => {
      return taxon.id === cur;
    });

    const sectionedItemsWithTaxon = getOrderedSectionWithItems({
      nextTaxon: taxonomyRootNode,
      items: sortedItemsWithoutTaxonomyChunking,
      catalog,
      catalogItemConfigMap,
      moduleSequenceMap,
      dominantTaxon
    });

    let flattenedItems = uniqBy(sectionedItemsWithTaxon.reduce((allItems, section) => {
      return [...allItems, ...section.items];
    }, []) as RecContentItemDto[], 'id');

    let sectionedItems = sectionedItemsWithTaxon.reduce((allItems, section) => {
      return [...allItems, ...section.items];
    }, []);

    const remainingItems = acc.remainingItems.filter((remainingItem: RecContentItemDto) => {
      return !flattenedItems.find(x => remainingItem.id === x.id);
    });

    if (arr.length - 1 === idx && remainingItems.length) {
      flattenedItems = [...flattenedItems, ...remainingItems];
      sectionedItems = [...sectionedItems, ...remainingItems];
    }

    return {
      sectionedItems: [...acc.sectionedItems, ...sectionedItems],
      flattenedItems: [...acc.flattenedItems, ...flattenedItems],
      remainingItems
    };
  }, {
    sectionedItems: [],
    flattenedItems: [],
    remainingItems: sortedItemsWithoutTaxonomyChunking
  });
};

export const getSherpathLesson = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathLessonExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_LESSON) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathLessonExternalEntityDtoParsed;
};

export const getSherpathSkill = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathSkillExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_SKILL) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathSkillExternalEntityDtoParsed;
};

export const getEaq = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogAdaptiveQuizExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.ADAPTIVE_QUIZ) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogAdaptiveQuizExternalEntityDtoParsed;
};

export const getSherpathSimulation = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathSimulationExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_SIMULATION) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathSimulationExternalEntityDtoParsed;
};

export const getSimchart = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSimChartExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SIM_CHART) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSimChartExternalEntityDtoParsed;
};

export const getEvolveAssetType = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): string => {
  if (!contentItem || !contentItem.type) {
    return null;
  }
  if (contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return null;
  }
  const evolveResource = getEvolveResource(contentItem, catalog);
  if (!evolveResource) {
    return null;
  }
  return evolveResource._parsedData.assetType;
};

export const transformTitle = (title: string, maxLength: number): string => {
  if (!title) {
    return title;
  }
  return truncateTitle(decode(title.replace(/<\/?[^>]+(>|$)/g, '')), maxLength);
};

export const getTitle = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  maxLength: number
  // eslint-disable-next-line sonarjs/cognitive-complexity
): string => {
  if (!contentItem || !contentItem.type) {
    return null;
  }

  if (contentItem.type === RecContentItemTypeDto.EBOOK_READING) {
    const ebookTitle = getEbookReadingTitle(contentItem, primaryTaxonomies);
    if (ebookTitle) {
      return transformTitle(ebookTitle, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
    const adaptiveLesson = getAdaptiveLesson(contentItem, catalog);
    if (adaptiveLesson) {
      return transformTitle(adaptiveLesson.entity.attributes.text || adaptiveLesson.entity.attributes.displayName, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
    const evolveResource = getEvolveResource(contentItem, catalog);
    if (evolveResource) {
      return transformTitle(evolveResource._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHADOW_HEALTH) {
    const shadowHealth = getShadowHealth(contentItem, catalog);
    if (shadowHealth) {
      return shadowHealth._parsedData.assignmentTitle;
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_SKILL) {
    const sherpathSkill = getSherpathSkill(contentItem, catalog);
    if (sherpathSkill) {
      return transformTitle(sherpathSkill._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_SIMULATION) {
    const sherpathSimulation = getSherpathSimulation(contentItem, catalog);
    if (sherpathSimulation) {
      return transformTitle(sherpathSimulation._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_LESSON) {
    const sherpathLesson = getSherpathLesson(contentItem, catalog);
    if (sherpathLesson) {
      return transformTitle(sherpathLesson._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SIM_CHART) {
    const simChart = getSimchart(contentItem, catalog);
    if (simChart) {
      return transformTitle(simChart._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_POWERPOINT) {
    const sherpathPowerpoint = getSherpathPowerpoint(contentItem, catalog);
    if (sherpathPowerpoint) {
      return transformTitle(sherpathPowerpoint._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY) {
    const sherpathGroupActivity = getSherpathGroupActivity(contentItem, catalog);
    if (sherpathGroupActivity) {
      return transformTitle(sherpathGroupActivity._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_CASE_STUDY) {
    const sherpathCaseStudy = getSherpathCaseStudy(contentItem, catalog);
    if (sherpathCaseStudy) {
      return transformTitle(sherpathCaseStudy._parsedData.title, maxLength);
    }
  }

  if (contentItem.type === RecContentItemTypeDto.OSMOSIS_VIDEO) {
    const osmosisVideo = getOsmosisVideo(contentItem, catalog);
    if (osmosisVideo) {
      return transformTitle(osmosisVideo._parsedData.title, maxLength);
    }
  }

  return transformTitle(`${contentItem.type} - ${contentItem.id}`, maxLength);

};

export const getSubtitle = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  maxLength: number
): string => {

  if (!contentItem) {
    return '';
  }

  if (contentItem.type === RecContentItemTypeDto.EBOOK_READING) {
    const ebookSubtitle = getEbookReadingSubtitle(contentItem, primaryTaxonomies);
    if (ebookSubtitle) {
      return transformTitle(ebookSubtitle, maxLength);
    }
  }

  if (!primaryTaxonomies || !primaryTaxonomies.length) {
    return '';
  }

  const relatedTaxons = contentItem.relationships.taxonomies.data.map(mapToIds);

  const subtitle = getTaxonsTitle(relatedTaxons, primaryTaxonomies, maxLength);

  if (!subtitle && contentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
    const evolveResource = getEvolveResource(contentItem, catalog);
    if (evolveResource && evolveResource._parsedData && evolveResource._parsedData.subtitle) {
      return transformTitle(evolveResource._parsedData.subtitle, maxLength);
    }
  }

  return subtitle;

};

export const getContentItemIncludes = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  isOsmosisEnabled: boolean
): string[] => {

  if (!isOsmosisEnabled) {
    return null;
  }

  if (!contentItem || !contentItem.type) {
    return null;
  }

  if (contentItem.type === RecContentItemTypeDto.SHERPATH_LESSON) {
    const sherpathLesson = getSherpathLesson(contentItem, catalog);
    if (
      sherpathLesson
      && sherpathLesson._parsedData
      && sherpathLesson._parsedData.osmosisVideos
      && sherpathLesson._parsedData.osmosisVideos.length > 0
    ) {
      return [CatalogItemInclude.OSMOSIS_VIDEO];
    }
  }

  return null;

};

export const getPaperBookCoverImageUrl = (isbn: string): string => {
  return `https://covers.elsevier.com/200fw/${isbn}.jpg`;
};

export const handleAddResourceButtonClickRedirect = (props: {
  action: SyllabusItemAction;
  courseSectionId: string;
  navigateToApp: NavigateToApp;
  parentSyllabusItem?: SyllabusItemDto;
  redirect: (path: string) => void;
  evolveProducts: EvolveProductDto[];
  selectedProduct: EvolveProductDto;
  location: LocationWithQuery;
}) => {

  const {
    action,
    courseSectionId,
    navigateToApp,
    parentSyllabusItem,
    redirect,
    evolveProducts,
    selectedProduct,
    location,
  } = props;

  const sharedEditorSearchParams = {
    [AssignmentEditorSearchParam.PARENT_SYLLABUS_ITEM_ID]: parentSyllabusItem ? parentSyllabusItem.id : null,
    [AssignmentEditorSearchParam.ISBN]: selectedProduct ? selectedProduct.isbn : null,
    [AssignmentEditorSearchParam.REF]: location.pathname,
  };

  const sharedAppLinkParams = stripNilProps({
    courseSectionId, // TODO: Remove this when AL team is ready
    selectedEntitlement: selectedProduct,
    parentSyllabusItemId: parentSyllabusItem ? parentSyllabusItem.id : null,
    courseEntitlements: evolveProducts, // TODO: Remove this when AL team is ready
  });

  switch (action) {
    case SyllabusItemAction.ADD_EBOOK_READING:
      redirect(addSearchParams(RoutePath.EBOOK_ASSIGNMENT_EDITOR, {
        ...sharedEditorSearchParams,
        [AssignmentEditorSearchParam.ISBN]: getVbId(selectedProduct)
        // Ebooks use the vital source isbn here as the identifier - maps to primary taxonomy rec response
      }));
      break;
    case SyllabusItemAction.ADD_EXTERNAL_LINK:
      redirect(addSearchParams(RoutePath.EXTERNAL_LINK_ASSIGNMENT_EDITOR, {
        ...sharedEditorSearchParams
      }));
      break;
    case SyllabusItemAction.ADD_ADAPTIVE_LESSON: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.KNOWLEDGECARD,
        action: appAction,
        body: sharedAppLinkParams
      });
      break;
    }
    case SyllabusItemAction.ADD_ASSESSMENT_BUILDER: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.EAB,
        action: appAction,
        body: sharedAppLinkParams,
        includeLinkHash: true
      });
      break;
    }
    case SyllabusItemAction.ADD_SIMCHART: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.SIMSNG,
        action: appAction,
        body: sharedAppLinkParams
      });
      break;
    }
    case SyllabusItemAction.ADD_ADAPTIVE_QUIZ: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.EAQ,
        action: appAction,
        body: sharedAppLinkParams
      });
      break;
    }
    default:
      break;
  }
};

export const getProductSelectOptionTitle = (props: {
  evolveProducts: EvolveProductDto[];
  evolveProductOption: EvolveProductDto;
  primaryTaxonomies: PrimaryTaxonomy[];
}): string => {
  if (props.evolveProductOption.productTypeKey === EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS) {
    // Find the primary taxonomy for book
    const primaryTaxonomy = props.primaryTaxonomies.find((_primaryTaxonomy) => {
      return _primaryTaxonomy.isbn === getVbId(props.evolveProductOption);
    });
    if (primaryTaxonomy) {
      return getEbookTitle(primaryTaxonomy.taxonomy);
    }
  }
  if (props.evolveProductOption.productTypeKey === EvolveProductTypeKey.SHERPATH_COMPONENT_NSS) {
    // Find the parent product for EAQ subcomponents
    const parentEvolveProduct = props.evolveProducts.find((_evolveProduct) => {
      const evolveProductComponents: EvolveProductDto[] = flattenTree(_evolveProduct.components, 'components');
      return evolveProductComponents.find((__evolveProduct) => {
        return __evolveProduct.isbn === props.evolveProductOption.isbn;
      });
    });
    if (parentEvolveProduct) {
      return parentEvolveProduct.title;
    }
  }
  return props.evolveProductOption.title;
};

export const getPrimaryTaxonomiesFromEvolveProducts = (evolveProducts: EvolveProductDto[]): PrimaryTaxonomy[] => {

  const productTaxonomies: PrimaryTaxonomy[] = evolveProducts.map((product) => {
    return {
      isbn: product.isbn,
      isbnType: product.type,
      taxonomy: null
    };
  });

  const ebookTaxonomies: PrimaryTaxonomy[] = getEbookComponents(evolveProducts).map((evolveProduct) => {
    return {
      isbn: getVbId(evolveProduct),
      isbnType: EvolveProductType.EBOOK,
      taxonomy: null
    };
  });

  return [...ebookTaxonomies, ...productTaxonomies];
};

export const getProductSelectOptions = (props: {
  evolveProducts: EvolveProductDto[];
  evolveProductOptions: EvolveProductDto[];
  primaryTaxonomies: PrimaryTaxonomy[];
}): ELSDropDownOption[] => {
  const options = props.evolveProductOptions.map((product) => {
    return {
      name: getProductSelectOptionTitle({
        evolveProducts: props.evolveProducts,
        primaryTaxonomies: props.primaryTaxonomies,
        evolveProductOption: product
      }),
      value: product.isbn
    };
  });

  return [SELECT_OPTION, ...options];
};

export const handleAddResourceButtonClick = (props: {
  action: SyllabusItemAction;
  courseSectionId: string;
  evolveProducts: EvolveProductDto[];
  modalService: ELSModalServiceType;
  navigateToApp: NavigateToApp;
  parentSyllabusItem?: SyllabusItemDto;
  primaryTaxonomies: PrimaryTaxonomy[];
  redirect: (path: string) => void;
  location: LocationWithQuery;
}) => {
  const {
    action,
    parentSyllabusItem,
    evolveProducts,
  } = props;

  if (parentSyllabusItem) {
    ELSLoggingService.info(fileName, `clicked ${action} to ${parentSyllabusItem.title}`);
  } else {
    ELSLoggingService.info(fileName, `clicked ${action}`);
  }

  const products = getEvolveProductsForAction(action, evolveProducts);

  if (!products.length) {
    ELSLoggingService.warn(fileName, 'Content type does not have any matching course product entitlements');
  }

  if (action === SyllabusItemAction.ADD_ADAPTIVE_QUIZ) {
    handleAddResourceButtonClickRedirect({
      ...props,
      selectedProduct: null
    });
    return;
  }

  if (action === SyllabusItemAction.ADD_SIMCHART) {
    handleAddResourceButtonClickRedirect({
      ...props,
      selectedProduct: null
    });
    return;
  }

  if (products.length > 1) {
    return;
  }

  handleAddResourceButtonClickRedirect({
    ...props,
    selectedProduct: products[0]
  });

};

export const catalogContainsTypes = (catalog: CatalogWithExternalEntitiesDto, types: RecContentItemTypeDto[]) => {
  if (!catalog || !catalog.catalog || !catalog.catalog.data) {
    return false;
  }
  return catalog.catalog.data.some(item => types.includes(item.type));
};

export const getContentItemIdFromAssignment = (assignment: AssignmentDto): string => {

  if (!assignment) {
    return null;
  }

  if (assignment.contentId) {
    return `${assignment.isbn}/${assignment.contentId}`;
  }

  // This is for backwards compatibility and can be removed
  if (!assignment.assignmentGoals) {
    return null;
  }

  const contentItemGoal = assignment.assignmentGoals.find(assignmentGoal => assignmentGoal.goal !== 0);
  const contentItemId = contentItemGoal ? contentItemGoal.vtwId : '';

  return `${assignment.isbn}/${contentItemId}`;
};

export const getSortedSyllabusFolderOrderMap = (treeMapItems: SyllabusTreeMapItem[]): { [K: string]: { id: string; order: number } } => {
  return keyBy(
    treeMapItems.filter(treeMapItem => treeMapItem.syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER)
      .map((treeMapItem, idx) => ({ id: treeMapItem.syllabusItem.id, order: idx })),
    item => item.id,
  );
};

export const scrollToTop = (): void => {
  const element = document.querySelector('.c-scm-page__content');
  if (element) {
    element.scrollTo(0, 0);
  }
};

export const getLearningDurationFromExternalEntity = (externalEntity: CatalogExternalEntityDtoParsed, catalogItem: RecContentItemDto): number | null => {
  if (!catalogItem || catalogItem.type === RecContentItemTypeDto.SIM_CHART) {
    return null;
  }
  if (catalogItem && catalogItem.type === RecContentItemTypeDto.OSMOSIS_VIDEO) {
    const duration = get(externalEntity, '_parsedData.videoDuration', '0:0') as string;
    const [min = 0, sec = 0] = duration.split(':');
    return Number(min) * 60 + Number(sec);
  }
  return get(externalEntity, '_parsedData.lessonDuration', get(externalEntity, '_parsedData.learningDuration', null));
};

export const getEbookLearningDurationFromCatalogItem = (catalogItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]) => {
  const isbn = getIsbnFromEbookCatalogItemId(catalogItem.id);
  const pageRangesFromEbookCatalogItemId = getPageRangesFromEbookCatalogItemId(catalogItem.id);
  const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
  const chapterPageRangeMap = getChapterPageRangesFromPageRanges(pageRangesFromEbookCatalogItemId, bookTaxonomy);
  const pageRanges = Object.values(chapterPageRangeMap).join(EbookPageRangeSeparators.CHUNK_SEPARATOR);
  return getTimeEstimateInSecondFromPageRanges(pageRanges);
};

export const getCatalogItemConfig = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  isOsmosisEnabled: boolean
): CatalogItemConfig => {
  if (!contentItem || !catalog) {
    return null;
  }

  let learningDuration: number;
  if (contentItem.type !== RecContentItemTypeDto.EBOOK_READING) {
    const externalEntity = getExternalEntity(contentItem, catalog);
    learningDuration = getLearningDurationFromExternalEntity(externalEntity, contentItem);
  } else {
    learningDuration = getEbookLearningDurationFromCatalogItem(contentItem, primaryTaxonomies);
  }

  return {
    title: getTitle(contentItem, catalog, primaryTaxonomies, null),
    subtitle: getSubtitle(contentItem, catalog, primaryTaxonomies, null),
    includes: getContentItemIncludes(contentItem, catalog, isOsmosisEnabled),
    type: getSyllabusItemTypeFromCatalogItem(contentItem, catalog),
    evolveAssetType: getEvolveAssetType(contentItem, catalog),
    learningDuration,
  };
};

export const getCatalogItemConfigMap = (
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  isOsmosisEnabled = true
): CatalogItemConfigMap => {
  if (!catalog) {
    return {};
  }
  return catalog.catalog.data.reduce((map, item) => {
    const itemConfig: CatalogItemConfig = getCatalogItemConfig(item, catalog, primaryTaxonomies, isOsmosisEnabled);
    return {
      ...map,
      [item.id]: itemConfig
    };
  }, {});
};

export type PaginationConfig = {
  realizedPage: number;
  totalItems: number;
  sliceStart: number;
  sliceEnd: number;
  totalPages: number;
}

export const getCatalogPaginationConfig = (totalItems: number, pageSize: number, currentPage: number): PaginationConfig => {
  const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : 1;

  const realizedPage = totalPages < currentPage ? totalPages : currentPage;

  let sliceStart = (realizedPage - 1) * pageSize;

  if (totalItems < sliceStart) {
    sliceStart = 0;
  }

  const sliceEnd = sliceStart + pageSize;

  return {
    realizedPage,
    totalItems,
    sliceStart,
    sliceEnd,
    totalPages
  };
};

export const getRelatedProductsFromEvolve = (evolveProducts: EvolveProductDto[], ebook: EvolveProductDto): BundleMemberProductDto[] => {
  if (isEmpty(evolveProducts)) {
    return [];
  }

  let product: EvolveProductDto = null;
  evolveProducts.forEach(evolveProduct => {
    if (evolveProduct.components.some(component => (component.type === EvolveProductType.EBOOK && component.isbn === ebook.isbn))) {
      product = evolveProduct;
    }
  });

  if (isEmpty(product)) {
    return [];
  }

  if (!product.bundleMemberProduct || !product.bundleMemberProduct.length) {
    return [];
  }

  return product.bundleMemberProduct.filter((relatedProduct: BundleMemberProductDto) => {
    return relatedProduct.productTypeKey === BundleMemberProductTypeKey.BINDER_READY;
  });
};

export const getPaperBookUrl = (
  userRole: string, user: UserDto,
  registeredToken: string,
  relatedProducts: BundleMemberProductDto[],
  promotionCode: string
): string => {
  const searchParams = {
    action: 'purchase',
    productCodes: JSON.stringify(relatedProducts.map(item => parseInt(item.isbn, 10))),
    referredBy: 'lms',
    role: isStudent(userRole) ? 'student' : 'faculty',
    email: user.emailAddress,
    pc: promotionCode,
    token: registeredToken
  };

  const urlSearchParams = new URLSearchParams(searchParams);
  const baseUrl = ServerConstants[ELSCommonConfig.appProfile].evolveCartURL;
  return `${baseUrl}?${urlSearchParams.toString()}`;
};

const getIsPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isFeatureFlagBasedBatchContentEnabled: boolean
): boolean => {

  if (!recContentItem) {
    return null;
  }

  if (!isFeatureFlagBasedBatchContentEnabled) {
    if (!recContentItem.attributes) {
      return null;
    }
    return recContentItem.attributes.isPublished;
  }

  const featureFlagFound = featureFlagsGrouped.find((featureFlag) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (featureFlag.featureName !== SHER_EVOL_FEATURE_FLAG.CATALOG_ITEM_IS_PUBLISHED) {
      return false;
    }
    const foundGroup = featureFlag.groups.find((group) => {
      return recContentItem.id.includes(group);
    });
    return !!foundGroup;
  });

  if (!featureFlagFound) {
    return null;
  }

  if (featureFlagFound.featureValue === TRUE_VALUE) {
    return true;
  }

  if (featureFlagFound.featureValue === FALSE_VALUE) {
    return false;
  }

  return null;
};

export const isCatalogItemNotPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isBatchContentEnabled: boolean,
  isFeatureFlagBasedBatchContentEnabled: boolean
) => {
  // isBatchContentEnabled is here mostly to force engineers to check whether the mock feature is on
  if (!isBatchContentEnabled) {
    return false;
  }
  const isPublished = getIsPublished(recContentItem, featureFlagsGrouped, isFeatureFlagBasedBatchContentEnabled);
  return isPublished === false;
};

export const isCatalogItemPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isBatchContentEnabled: boolean,
  isFeatureFlagBasedBatchContentEnabled: boolean
) => {
  // isBatchContentEnabled is here mostly to force engineers to check whether the mock feature is on
  if (!isBatchContentEnabled) {
    return false;
  }
  const isPublished = getIsPublished(recContentItem, featureFlagsGrouped, isFeatureFlagBasedBatchContentEnabled);
  return isPublished === true;
};

export const getTaxonMap = (catalog: CatalogWithExternalEntitiesDto): Record<string, RecTaxonomyNodeDto> => {
  if (!catalog || !catalog.catalog || !catalog.catalog.included || !catalog.catalog.included.length) {
    return {};
  }
  return catalog.catalog.included.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: cur
    };
  }, {});
};

export const getModuleSequenceIds = (props: {
  catalog: CatalogWithExternalEntitiesDto;
}): {
  moduleSequenceId: string;
  taxon: RecTaxonomyNodeDto;
}[] => {

  const {
    catalog,
  } = props;

  if (!catalog || !catalog.catalog || !catalog.catalog.data || !catalog.catalog.data.length) {
    return [];
  }

  const sequenceMappedItems = catalog.catalog.data.filter((item) => {
    return SequenceMappedTypes.includes(item.type);
  });

  if (!sequenceMappedItems || !sequenceMappedItems.length) {
    return [];
  }

  const taxonMap = getTaxonMap(catalog);

  return sequenceMappedItems.reduce((acc, catalogItem) => {
    if (!catalogItem.relationships || !catalogItem.relationships.taxonomies) {
      return acc;
    }
    return catalogItem.relationships.taxonomies.data.reduce((_acc, taxonomyRelationship) => {
      if (!catalogItem.attributes.isbn) {
        return _acc;
      }
      // We originally planned to fetch modules by taxonomy id only until we realized that sequences are different for the same taxonomy id
      // within different products (aka ISBNs). To preserve the existing API structure we decided to store sequences against an id that was a
      // concatenation of the isbn and the taxon id
      const moduleSequenceId = `${catalogItem.attributes.isbn}_${taxonomyRelationship.id}`;
      if (_acc.some((item) => {
        return item.moduleSequenceId === moduleSequenceId;
      })) {
        return _acc;
      }
      const taxon = taxonMap[taxonomyRelationship.id];
      return [..._acc, { moduleSequenceId, taxon }];
    }, acc);
  }, [] as {
    moduleSequenceId: string;
    taxon: RecTaxonomyNodeDto;
  }[]);

};

export const getOsmosisTokenPromise = (props: {
  isOsmosisVideosEnabled: boolean;
  catalog: CatalogWithExternalEntitiesDto;
  contentItem: RecContentItemDto;
  fetchOsmosisTokenAction: Function;
}): Promise<OsmosisTokenDto> => {

  const {
    isOsmosisVideosEnabled,
    catalog,
    contentItem,
    fetchOsmosisTokenAction
  } = props;

  if (!isOsmosisVideosEnabled) {
    return Promise.resolve(null);
  }

  const itemIncludes = getContentItemIncludes(contentItem, catalog, isOsmosisVideosEnabled);
  const hasOsmosisVideo = itemIncludes && itemIncludes.some((item: CatalogItemInclude) => {
    return item === CatalogItemInclude.OSMOSIS_VIDEO;
  });
  return hasOsmosisVideo ? fetchOsmosisTokenAction() : Promise.resolve(null);
};
