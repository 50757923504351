export enum OsmosisAssignmentPlayerQueryParams {
  assignmentId = 'assignmentId',
  syllabusItemId = 'syllabusItemId',
  catalogItemId = 'catalogItemId',
  osmosisVideoId = 'osmosisVideoId',
  osmosisVideoTitle = 'osmosisVideoTitle',
  osmosisVideoUrl = 'https://www.osmosis.org',
  ref = 'ref'
}

export enum SkillAssignmentPlayerParam {
  assignmentId = 'assignmentId',
  syllabusItemId = 'syllabusItemId',
  skillBlockId = 'skillBlockId',
  appLinkAction= 'appLinkAction',
  ref = 'ref'
}

export enum GroupActivityParam {
  VTW_ID = 'vtwId',
  syllabusItemId = 'syllabusItemId',
  ref = 'ref'
}

export enum SimulationPlayerPageParam {
  simulationId = 'simulationId',
  assignmentId = 'assignmentId',
  syllabusItemId = 'syllabusItemId',
  ref = 'ref',
}

export enum CaseStudyParam {
  VTW_ID = 'vtwId',
  syllabusItemId = 'syllabusItemId',
  ref = 'ref'
}

export const dueDateExtensionWarning = `In some Learning Management Systems grades can only be set from third party applications one time.
For assignments where the due date is in the past, grades that have been synced cannot be updated.
You may extend the due date and view updated grades and scores in the Performance and Grades section of the application.`;
