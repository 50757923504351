import moment, {
  Moment,
  MomentFormatSpecification
} from 'moment';
import momentTimezone from 'moment-timezone';
import {
  isArray,
  isEmpty,
  isNil,
  isString
} from 'lodash';
import messagesDE from '../translations/de';
import messagesEN from '../translations/en';
import { DATE_FORMAT } from '../constants/date.constants';
import { ELSDropDownOption } from '../components/els.components';
import { EvolveProductDto } from '../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { Application } from '../apis/eols-app-link/eols-app-link.constants';

export const formatDate = (str: string) => moment(str).format(DATE_FORMAT);

export const parseDate = (str: string) => {
  const valid = moment(str, DATE_FORMAT, true).isValid();
  return valid ? moment(str, DATE_FORMAT, true).toDate() : undefined;
};

export const getLocalMomentInsFromServicesUTC = (date: string, format?: MomentFormatSpecification): Moment => {
  return moment.utc(date, format).local();
};

export const getLocalDateFromServicesUTC = (date: string): Date => {
  return getLocalMomentInsFromServicesUTC(date).toDate();
};

export const getWindowMode = () => {
  const { innerWidth } = window;
  const mode = { mobile: false, tablet: false, desktop: false, wide: false };
  if (innerWidth < 600) {
    return { ...mode, mobile: true };
  }
  if (innerWidth >= 600 && innerWidth < 900) {
    return { ...mode, tablet: true };
  }
  if (innerWidth >= 900 && innerWidth < 1200) {
    return { ...mode, desktop: true };
  }
  if (innerWidth >= 1200) {
    return { ...mode, wide: true };
  }
  return mode;
};

export const getTopPosition = () => window.pageYOffset || document.documentElement.scrollTop;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCleanSearchParamsFromObject = (searchParams: Record<string, any>): URLSearchParams => {
  const _searchParams = new URLSearchParams();
  Object.keys(searchParams).forEach(key => {
    const val = searchParams[key];
    if (isNil(val)) {
      return;
    }
    if (isArray(val) && !val.length) {
      return;
    }
    if (isString(val)) {
      _searchParams.append(key.toString(), val);
    } else if (isArray(val)) {
      val.forEach(valItem => {
        if (!isNil(valItem)) {
          _searchParams.append(key.toString(), valItem.toString());
        }
      });
    } else {
      _searchParams.append(key.toString(), JSON.stringify(val));
    }
  });
  return _searchParams;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addSearchParams = (url: string, searchParams: Record<string, any>): string => {

  if (!searchParams) {
    return url;
  }

  const urlParts = url.split('?');
  const baseUrl = urlParts[0];
  const existingParams = urlParts[1] ? urlParts[1] : '';

  const urlSearchParams = getCleanSearchParamsFromObject(searchParams);

  const combinedParams = existingParams
    ? `${existingParams}&${urlSearchParams.toString()}`
    : urlSearchParams.toString();

  if (!combinedParams) {
    return url;
  }
  return `${baseUrl}?${combinedParams}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteSearchParams = (url: string, existingSearchParams: Record<string, any>, deletingParams: string[]): string => {
  const urlSearchParams = getCleanSearchParamsFromObject(existingSearchParams);
  deletingParams.forEach(param => urlSearchParams.has(param) && urlSearchParams.delete(param));
  if (isEmpty(urlSearchParams.toString())) {
    return url;
  }
  return `${url}?${urlSearchParams.toString()}`;
};

export const isValidDate = (str: string | Date, format: MomentFormatSpecification = DATE_FORMAT, strict = true) => {
  return moment(str, format, strict).isValid();
};

/**
 * There are 2 fields in DateTimeComponent: DateComponent and TimeComponent
 * originalDateInput is only bubbled up when we change value in DateComponent
 * originalDateInput is string while value is the unstrictly converted Date so have to validate originalDateInput to ensure correct format
 *
 */
export const validateDateTimeChange = (value: Date, originalDateInput: string): boolean => {
  if (originalDateInput === undefined) {
    return isValidDate(value);
  }
  return isValidDate(originalDateInput);
};

export const messages: Record<string, Record<string, string>> = {
  de: messagesDE,
  en: messagesEN
};

export const getLanguage = () => {
  const defaultLanguage = 'en';
  let browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language;
  const supporting = ['en', 'de'];
  if (supporting.includes(browserLanguage)) {
    return browserLanguage;
  }
  browserLanguage = browserLanguage.substring(0, 2);

  if (supporting.includes(browserLanguage)) {
    return browserLanguage;
  }

  return defaultLanguage;
};

export const SELECT_OPTION: ELSDropDownOption = {
  name: '--Select--',
  value: ''
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getApplicationContext = (evolveProducts: EvolveProductDto[]): Application => {
  return Application.ASSIGNMENT_MANAGEMENT;
  // if (!evolveProducts || !evolveProducts.length) {
  //   return Application.SHER_EVOL;
  // }
  // const sherpathProduct = evolveProducts.find((product) => {
  //   return [EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED, EvolveProductTypeKey.SHERPATH_IA].includes(product.productTypeKey);
  // });
  // if (sherpathProduct) {
  //   return Application.SHER_EVOL;
  // }
  // const simChartProduct = evolveProducts.find((product) => {
  //   return EvolveProductTypeKey.SIMULATION_SIM_CHART_NG === product.productTypeKey;
  // });
  // if (simChartProduct) {
  //   return Application.SIMSNG;
  // }
  // const eabProduct = evolveProducts.find((product) => {
  //   return EvolveProductTypeKey.ELSEVIER_ASSESSMENT_BUILDER === product.productTypeKey;
  // });
  // if (eabProduct) {
  //   return Application.EAB;
  // }
  // return Application.SHER_EVOL;
};

export const getWordMark = (evolveProducts: EvolveProductDto[]): string => {
  const application = getApplicationContext(evolveProducts);
  if (application === Application.SIMSNG) {
    return 'SimChart';
  }
  if (application === Application.EAB) {
    return 'Assessment Builder';
  }
  return 'Sherpath';
};

const regexAnyDigit = '\\d';

export const getClientZoneAbbr = () => {
  const clientZone = momentTimezone.tz.guess();
  const currentZoneAbbr = momentTimezone.tz(momentTimezone(), clientZone).zoneAbbr();
  const doesContainDigit = new RegExp(regexAnyDigit).test(currentZoneAbbr);
  if (doesContainDigit) {
    return clientZone;
  }
  return currentZoneAbbr;
};

export const clientTimeZone = momentTimezone.tz.guess();

export const clientZoneAbbr = getClientZoneAbbr();

export const convertIsbnsToNormalizedString = (isbns: string[]): string => {
  if (!isbns || !isbns.length) {
    return '';
  }
  return isbns.sort().join(',');
};
