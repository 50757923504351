export enum AssignmentEditorSearchParam {
  ACTIVE_STEP_INDEX = 'activeStepIndex',
  ASSIGNMENT_ID = 'assignmentId',
  ISBN = 'isbn',
  PARENT_SYLLABUS_ITEM_ID = 'parentSyllabusItemId',
  SYLLABUS_ITEM_ID = 'syllabusItemId',
  PAGE_RANGES = 'pageRanges',
  CATALOG_ITEM_ID = 'catalogItemId',
  REF = 'ref',
  ASSIGNMENT_STATE = 'assignmentState',
}
