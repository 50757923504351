import moment from 'moment';
import { get } from 'lodash';
import {
  CatalogWithExternalEntitiesDto,
  SyllabusAssignmentDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  getLocalDateFromServicesUTC,
  isValidDate
} from '../../utilities/app.utilities';
import {
  SHADOW_HEALTH_ASSIGNMENT_EDITOR_PAGE_TITLE,
  ShadowHealthDefaultSteps,
  ShadowHealthStep
} from './shadow-health-assignment.constants';
import {
  BaseAssignmentEditorProps,
  BaseAssignmentEditorState
} from '../../hocs/with-base-assignment-editor/withBaseAssignmentEditor.hoc';
import {
  getDefaultAssignmentEditorState,
  updateStepsOnNav
} from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import { AssignmentFormError } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.models';
import {
  AssignmentDto, AssignmentGradeType,
  AssignmentType
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  getSubtitle,
  getTitle
} from '../catalog/catalog.utilities';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../course-plan/syllabus.constants';
import { MAX_ASSIGNMENT_TITLE_CHAR_LENGTH } from '../../components/assignment-editor/assignment-editor.constant';
import { ScoredOnlyAssignmentType } from '../bulk-edit-settings/bulk-edit-settings.constants';
import { BulkEditSettingsField } from '../bulk-edit-settings/bulk-edit-settings.models';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { isGraded, isValidGradePoints } from '../../components/assignment-editor/assignment-editor.utilities';

export const validateShadowHealthAssignment = (
  syllabusAssignmentDto: SyllabusAssignmentDto,
  baseState: BaseAssignmentEditorState,
  baseProps: BaseAssignmentEditorProps
// eslint-disable-next-line sonarjs/cognitive-complexity
): AssignmentFormError[] => {
  const { messages } = baseProps;
  const errors = [];

  const assignmentGoals = get(syllabusAssignmentDto, 'assignment.assignmentGoals', []);

  if (!assignmentGoals.length || !syllabusAssignmentDto.assignment.contentId) {
    errors.push({
      message: messages.ASSIGNMENT_CONTENT_ID_IS_EMPTY,
      stepId: ShadowHealthStep.STEP1
    });
  }

  if (!syllabusAssignmentDto.assignment.title || !syllabusAssignmentDto.assignment.title.length) {
    errors.push({
      message: messages.ASSIGNMENT_TITLE_IS_EMPTY,
      stepId: ShadowHealthStep.STEP1
    });
  }

  const availableDate = moment(syllabusAssignmentDto.assignment.availableDate).toDate();
  if (baseState.hasAvailableDate && !isValidDate(availableDate)) {
    errors.push({
      message: messages.INVALID_AVAILABILITY_DATE,
      stepId: ShadowHealthStep.STEP1
    });
  }

  const dueDate = moment(syllabusAssignmentDto.assignment.dueDate).toDate();
  if (baseState.hasDueDate && !isValidDate(dueDate)) {
    errors.push({
      message: messages.INVALID_DUE_DATE,
      stepId: ShadowHealthStep.STEP1
    });
  }

  if (baseState.hasDueDate
    && moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.dueDate))
      .isSameOrBefore(moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.availableDate)))) {
    errors.push({
      message: messages.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE,
      stepId: ShadowHealthStep.STEP1
    });
  }

  if (
    baseState.assignment
    && baseState.assignment.title
    && baseState.assignment.title.length > MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
  ) {
    errors.push({
      message: messages.ENTRY_EXCEEDS_LENGTH_LIMIT,
      stepId: ShadowHealthStep.STEP1,
    });
  }

  if (
    baseState.assignment
    && baseState.assignment.assignmentGradeType === AssignmentGradeType.PASS_FAIL
    && Object.values(ScoredOnlyAssignmentType).includes(baseState.assignment.assignmentType)
  ) {
    errors.push({
      message: messages.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_PASS_FAIL_GRADING,
      fields: [BulkEditSettingsField.GRADING]
    });
  }

  const {
    featureFlagsGrouped,
    courseSectionId
  } = baseProps;

  const showGradePoints = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.IS_CUSTOM_GRADE_POINTS_ENABLED,
    courseSectionId
  ) : false;

  if (
    baseState.assignment
    && showGradePoints
    && isGraded(baseState.assignment)
    && !isValidGradePoints(baseState.assignment.gradePoints)
  ) {
    errors.push({
      message: messages.INVALID_GRADE_POINTS,
      stepId: ShadowHealthStep.STEP1,
    });
  }

  return errors;
};

export const getShadowHealthDefaultState = (): BaseAssignmentEditorState => {
  return {
    ...getDefaultAssignmentEditorState(),
    assignmentType: AssignmentType.SHADOW_HEALTH,
    hasDueDate: true,
    showDueDate: true,
    showGrading: true,
    showDisplayName: true,
    pageTitle: SHADOW_HEALTH_ASSIGNMENT_EDITOR_PAGE_TITLE,
    steps: updateStepsOnNav(ShadowHealthDefaultSteps, 0),
    isReadOnlyDisplayName: false,
  };
};

export const getShadowHealthAssignmentInfo = (catalog: CatalogWithExternalEntitiesDto, contentItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]): {
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
  contentId: AssignmentDto['contentId'];
  assignmentGoals: AssignmentDto['assignmentGoals'];
} => {
  const contentId = get(contentItem, 'attributes.contentId', null);
  return {
    title: getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
    subtitle: getSubtitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
    contentId,
    assignmentGoals: [{
      id: null,
      goal: 100,
      vtwId: contentId,
      text: null,
    }],
  };
};
