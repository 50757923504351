import { v4 as uuidv4 } from 'uuid';
import {
  includes,
  isInteger,
  orderBy,
  times,
  uniq,
  uniqBy
} from 'lodash';
import moment from 'moment';
import React from 'react';
import { ELSLoggingService } from '@els/els-ui-common-react';
import {
  RecContentItemDto,
  RecContentItemTypeDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import {
  getCatalogItemConfigMap,
  getEvolveResource,
  getUniqueSortedPrimaryTaxonomies,
  getSubtitle,
  getSyllabusItemTypeFromCatalogItem,
  getTaxonomiesWithFlattenedBranchChildren,
  getTitle,
  isEvolveResourceSupported,
  isTypeInSelectedTypes,
  sortCatalogItems,
  getEbookNodeTitle,
} from '../catalog/catalog.utilities';
import {
  DATE_PRIMARY,
  DATE_PRIMARY_CHUNK
} from '../../constants/date.constants';
import {
  CourseBuilderConfig,
  CourseCopyPreviewWarningContentItem,
  SyllabusTreeMapItem,
} from './courseBuilder.models';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  ActiveSyllabusItemTypeDto,
  ExternalIdTypeDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import {
  getAncestorLineFolderIds,
  sortDefault
} from '../course-plan/syllabus.utilities';
import {
  contentCreateMethod,
  COURSE_NAME_MAX_LENGTH,
  SherpathContentType,
  SherpathContentTypeMap
} from './courseBuilder.constants';
import { isValidDate } from '../../utilities/app.utilities';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { ELSCourseSectionDto } from '../../models/els.dtos';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  CatalogEvolveResourcePermission,
  CatalogWithExternalEntitiesDto,
  CourseCopyPreviewDto,
  SherpathClassicContentItemDto,
  SherpathContentTypeDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  joinListCommaSeparated,
  mapToIds,
  stitchArrays,
  truncateTitle
} from '../../utilities/common.utilities';
import { CatalogItemConfigMap } from '../catalog/catalog.models';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH
} from '../course-plan/syllabus.constants';
import { DefaultCoursePlanEvolveResourceTypeDEPRECATED } from '../../constants/content-type.constants';
import {
  SequenceMap
} from '../../apis/ocs-api-service/ocs-api-service.dtos';

const fileName = 'courseBuilder.utilities';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getSectionDurationDays = (courseBuilderConfig: CourseBuilderConfig): number => {
  return 7;
  // TODO: Clean me up if this feature passes testing and solidified into product
  // const numberOfDays = moment(courseBuilderConfig.endDate).diff(moment(courseBuilderConfig.startDate), 'days');
  // return Math.ceil(numberOfDays / courseBuilderConfig.numberOfSections);
};

export const getSectionTitle = (
  courseBuilderConfig: CourseBuilderConfig,
  sectionIndex: number
): string => {
  const baseTitle = `${courseBuilderConfig.sectionTitle} ${sectionIndex + 1}`;

  if (!courseBuilderConfig.isIncludeDurationInTitle) {
    return baseTitle;
  }

  const sectionDurationDays = getSectionDurationDays(courseBuilderConfig);

  const sectionStart = moment(courseBuilderConfig.startDate)
    .add(sectionIndex * sectionDurationDays, 'days');

  const sectionEnd = moment(sectionStart)
    .add(sectionDurationDays - 1, 'days');

  return `${baseTitle}: ${sectionStart.format(DATE_PRIMARY_CHUNK)} - ${sectionEnd.format(DATE_PRIMARY_CHUNK)}`;
};

export const buildSections = (courseBuilderConfig: CourseBuilderConfig): SyllabusItemDto[] => {
  const sectionHeaders = [];

  times(courseBuilderConfig.numberOfSections, (number) => {
    const newSyllabusItem: SyllabusItemDto = {
      id: uuidv4(),
      parentId: null,
      title: getSectionTitle(courseBuilderConfig, number),
      subtitle: null,
      type: ActiveSyllabusItemTypeDto.FOLDER,
      displayOrder: number,
      externalIdentifiers: [],
      courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)]
    };
    sectionHeaders.push(newSyllabusItem);
  });

  return sectionHeaders;
};

// This method evenly distributes extra subsections throughout a course
export const getSectionIdxFromEvenlyDistributedSubSectionIdx = (
  numberOfSections: number,
  numberOfSubsections: number,
  subSectionIdx: number
): number => {

  if (numberOfSections <= 0) {
    return -1;
  }

  if (subSectionIdx >= numberOfSubsections) {
    return -1;
  }

  if (numberOfSections > numberOfSubsections) {
    return subSectionIdx;
  }

  const approxSectionLocation = (subSectionIdx / numberOfSubsections) * numberOfSections;

  return Math.floor(approxSectionLocation);
};

export const buildContentItem = (
  courseBuilderConfig: CourseBuilderConfig,
  contentItem: RecContentItemDto,
  parentId: string,
  displayOrder: number,
): SyllabusItemDto => {
  // Allowing the title and subtitle to run over 255 char limit here for the preview
  // Will truncate them down before saving to database
  return {
    id: uuidv4(),
    parentId,
    title: getTitle(contentItem, courseBuilderConfig.catalog, courseBuilderConfig.primaryTaxonomies, null),
    subtitle: getSubtitle(contentItem, courseBuilderConfig.catalog, courseBuilderConfig.primaryTaxonomies, null),
    type: getSyllabusItemTypeFromCatalogItem(contentItem, courseBuilderConfig.catalog),
    displayOrder,
    externalIdentifiers: [{
      type: ExternalIdTypeDto.CATALOG_ITEM_ID,
      value: contentItem.id
    }],
    courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)],
  };
};

export const isResourceIncludedInDefaultCoursePlan = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  unSupportedEvolveResourceTypes: string[],
  courseBuilderEvolveResourceTypes: string[],
): boolean => {

  if (contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return true;
  }

  const evolveResource = getEvolveResource(contentItem, catalog);

  if (!evolveResource || !evolveResource._parsedData || !evolveResource._parsedData.permission) {
    return true;
  }

  if (!isEvolveResourceSupported(evolveResource, unSupportedEvolveResourceTypes)) {
    return false;
  }

  // Fall back to the old way so as not to break production courses that do not yet have new assetType props
  if (!evolveResource._parsedData.assetType && evolveResource._parsedData.type) {
    if (evolveResource._parsedData.permission.trim().toLowerCase() === CatalogEvolveResourcePermission.STUDENT) {
      return false;
    }
    const normalizedType = evolveResource._parsedData.type.trim().toLowerCase();
    const permittedEvolveResourceTypesDEPRECATED = [
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.CASE_STUDIES.toLowerCase(),
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.CASE_STUDY.toLowerCase(),
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.TEACH.toLowerCase(),
      DefaultCoursePlanEvolveResourceTypeDEPRECATED.PPT.toLowerCase(),
    ];

    return permittedEvolveResourceTypesDEPRECATED.includes(normalizedType);
  }

  const normalizedType = evolveResource._parsedData.assetType.trim();
  return courseBuilderEvolveResourceTypes.includes(normalizedType);
};

const filterIsTypeIncluded = (courseBuilderConfig: CourseBuilderConfig) => (contentItem: RecContentItemDto): boolean => {

  if (!isResourceIncludedInDefaultCoursePlan(
    contentItem,
    courseBuilderConfig.catalog,
    courseBuilderConfig.unSupportedEvolveResourceTypes,
    courseBuilderConfig.courseBuilderEvolveResourceTypes
  )) {
    return false;
  }

  return isTypeInSelectedTypes(
    courseBuilderConfig.selectedTypes,
    getSyllabusItemTypeFromCatalogItem(contentItem, courseBuilderConfig.catalog)
  );
};

export const buildSubsectionContentItems = (
  courseBuilderConfig: CourseBuilderConfig,
  subSectionSyllabusItem: SyllabusItemDto,
  subSectionTaxonomyNode: RecTaxonomyNodeDto,
  catalogItemConfigMap: CatalogItemConfigMap,
  moduleSequenceMap: SequenceMap
): SyllabusItemDto[] => {
  // Include children nodes here as well
  const nodeIds = getTaxonomiesWithFlattenedBranchChildren(courseBuilderConfig.catalog.catalog.included, [subSectionTaxonomyNode.id]).map(mapToIds);

  const includedContentItems = courseBuilderConfig.catalog.catalog.data
    .filter(filterIsTypeIncluded(courseBuilderConfig))
    .filter((contentItem) => {
      return contentItem.relationships.taxonomies.data.find((taxonomy) => {
        return nodeIds.includes(taxonomy.id);
      });
    });

  const sortedItems = sortCatalogItems({
    items: includedContentItems,
    sortedTaxonomyIds: getUniqueSortedPrimaryTaxonomies(courseBuilderConfig.primaryTaxonomies).map(x => x.taxonomy.data[0].id),
    catalog: courseBuilderConfig.catalog,
    catalogItemConfigMap,
    moduleSequenceMap,
    dominantTaxon: subSectionTaxonomyNode
  }).sectionedItems;

  return sortedItems.map((contentItem, idx) => {
    return buildContentItem(courseBuilderConfig, contentItem, subSectionSyllabusItem.id, idx);
  });
};

export const getSubSectionTaxonomyNodes = (courseBuilderConfig: {
  taxonomyIds: CourseBuilderConfig['taxonomyIds'];
  catalog: CourseBuilderConfig['catalog'];
}): RecTaxonomyNodeDto[] => {

  if (!courseBuilderConfig.taxonomyIds || !courseBuilderConfig.taxonomyIds.length) {
    return [];
  }

  const arrayOfTaxonomyNodeArray = courseBuilderConfig.taxonomyIds.reduce((acc, cur) => {
    const taxonomy = courseBuilderConfig.catalog.catalog.included.find((taxonomyNode) => {
      return taxonomyNode.id === cur;
    });

    if (!taxonomy) {
      // This should only happen if there is no primary taxonomy for an isbn (which is a content bug)
      ELSLoggingService.error(fileName, 'Taxonomy note found: isbn primary taxonomy mismatch with catalog api response');
      return acc;
    }

    const taxonomyChildrenNodes = taxonomy.relationships.children.data.map((child) => {
      return courseBuilderConfig.catalog.catalog.included.find((taxonomyNode) => {
        return taxonomyNode.id === child.id;
      });
    });

    return [
      ...acc,
      orderBy(taxonomyChildrenNodes, 'attributes.displayOrder')
    ];
  }, []);

  return stitchArrays(arrayOfTaxonomyNodeArray) as RecTaxonomyNodeDto[];
};

export const buildSubsections = (
  courseBuilderConfig: CourseBuilderConfig,
  sectionHeaders: SyllabusItemDto[],
  isOsmosisEnabled: boolean
): SyllabusItemDto[] => {

  const subSectionTaxonomyNodes = getSubSectionTaxonomyNodes(courseBuilderConfig);

  if (subSectionTaxonomyNodes.length === 0) {

    const items = courseBuilderConfig.catalog.catalog.data
      .filter(filterIsTypeIncluded(courseBuilderConfig));

    const sortedTaxonomyIds = courseBuilderConfig.primaryTaxonomies
      ? getUniqueSortedPrimaryTaxonomies(courseBuilderConfig.primaryTaxonomies).map(x => x.taxonomy.data[0].id)
      : [];

    const sortedItems = sortCatalogItems({
      items,
      sortedTaxonomyIds,
      catalog: courseBuilderConfig.catalog,
      catalogItemConfigMap: getCatalogItemConfigMap(
        courseBuilderConfig.catalog,
        courseBuilderConfig.primaryTaxonomies,
        isOsmosisEnabled
      ),
      moduleSequenceMap: courseBuilderConfig.moduleSequenceMap,
      dominantTaxon: null
    }).sectionedItems;

    return sortedItems.map((contentItem, idx, arr) => {
      const currentSectionIdx = getSectionIdxFromEvenlyDistributedSubSectionIdx(
        sectionHeaders.length,
        arr.length,
        idx
      );
      return buildContentItem(
        courseBuilderConfig,
        contentItem,
        sectionHeaders[currentSectionIdx].id,
        idx,
      );
    });
  }

  const catalogItemConfigMap = getCatalogItemConfigMap(
    courseBuilderConfig.catalog,
    courseBuilderConfig.primaryTaxonomies,
    isOsmosisEnabled
  );

  return subSectionTaxonomyNodes.reduce((
    subSections: SyllabusItemDto[],
    subSectionTaxonomyNode: RecTaxonomyNodeDto,
    subSectionIdx,
    _subSectionTaxonomyNodes
  ): SyllabusItemDto[] => {

    const currentSectionIdx = getSectionIdxFromEvenlyDistributedSubSectionIdx(
      sectionHeaders.length,
      _subSectionTaxonomyNodes.length,
      subSectionIdx
    );

    const subSectionSyllabusItem: SyllabusItemDto = {
      id: uuidv4(),
      parentId: sectionHeaders[currentSectionIdx].id,
      title: getEbookNodeTitle(subSectionTaxonomyNode),
      subtitle: null,
      type: ActiveSyllabusItemTypeDto.FOLDER,
      displayOrder: subSectionIdx,
      externalIdentifiers: [{
        type: ExternalIdTypeDto.TAXONOMY_NODE_ID,
        value: subSectionTaxonomyNode.id.toString()
      }],
      courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)],
    };

    const subSectionContentItems = buildSubsectionContentItems(
      courseBuilderConfig,
      subSectionSyllabusItem,
      subSectionTaxonomyNode,
      catalogItemConfigMap,
      courseBuilderConfig.moduleSequenceMap
    );

    return [...subSections, subSectionSyllabusItem, ...subSectionContentItems];
  }, []);
};

const areCourseBuilderConfigDatesValid = (courseBuilderConfig: CourseBuilderConfig): boolean => {
  if (!courseBuilderConfig.isIncludeDurationInTitle) {
    return true;
  }

  if (!courseBuilderConfig.startDate) {
    return false;
  }

  if (!courseBuilderConfig.endDate) {
    return false;
  }

  if (!isValidDate(courseBuilderConfig.startDate)) {
    return false;
  }

  if (!isValidDate(courseBuilderConfig.endDate)) {
    return false;
  }

  return !moment(courseBuilderConfig.endDate).isSameOrBefore(moment(courseBuilderConfig.startDate));
};

export const isCourseBuilderConfigValid = (courseBuilderConfig: CourseBuilderConfig): boolean => {

  if (!courseBuilderConfig.courseName) {
    return false;
  }

  if (!courseBuilderConfig.courseName.trim() || courseBuilderConfig.courseName.trim().length > COURSE_NAME_MAX_LENGTH) {
    return false;
  }

  if (courseBuilderConfig.contentCreateMethod === contentCreateMethod.BLANK) {
    return !!courseBuilderConfig.firstFolderTitle;
  }

  // Everything after here is checking the custom course builder conditions

  if (!courseBuilderConfig.numberOfSections || courseBuilderConfig.numberOfSections <= 0 || courseBuilderConfig.numberOfSections > 999) {
    return false;
  }

  if (!isInteger(courseBuilderConfig.numberOfSections)) {
    return false;
  }

  return areCourseBuilderConfigDatesValid(courseBuilderConfig);

};

export const buildCourseSyllabusItems = (courseBuilderConfig: CourseBuilderConfig, isOsmosisEnabled: boolean): SyllabusItemDto[] => {

  if (courseBuilderConfig.contentCreateMethod === contentCreateMethod.BLANK) {
    return [{
      id: uuidv4(),
      parentId: null,
      title: courseBuilderConfig.firstFolderTitle,
      subtitle: null,
      type: ActiveSyllabusItemTypeDto.FOLDER,
      displayOrder: 0,
      externalIdentifiers: [],
      courseSectionIds: [parseInt(courseBuilderConfig.courseSectionId, 10)],
    }];
  }

  const sections = buildSections(courseBuilderConfig);
  const subSections = buildSubsections(courseBuilderConfig, sections, isOsmosisEnabled);
  return [...sections, ...subSections];
};

export const getTreeMap = (
  syllabusItems: SyllabusItemDto[],
  syllabusItem?: SyllabusItemDto,
  level = 1
): SyllabusTreeMapItem[] => {

  const items = syllabusItems.filter((item) => {
    if (!syllabusItem) {
      return !item.parentId;
    }

    return item.parentId === syllabusItem.id;
  });

  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => {
    return {
      syllabusItem: item,
      level,
      children: getTreeMap(syllabusItems, item, level + 1)
    };
  });
};

export const flattenTreeMap = (treeMapItems: SyllabusTreeMapItem[]): SyllabusTreeMapItem[] => {
  return treeMapItems.reduce((acc, cur) => {
    if (cur.children && cur.children.length) {
      return [...acc, cur, ...flattenTreeMap(cur.children)];
    }
    return [...acc, cur];
  }, []);
};

export const getSortedSyllabusTreeMapItems = (syllabus: SyllabusItemDto[]): SyllabusTreeMapItem[] => {
  const flatSortedList = sortDefault(syllabus.filter((item) => !item.isDeleted));
  const treeMap = getTreeMap(flatSortedList);
  return flattenTreeMap(treeMap);
};

export const getEndDate = (
  startDate: Date,
  sectionDurationDays: number,
  numberOfSections: number
): string => {
  if (isNaN(sectionDurationDays) || isNaN(numberOfSections)) {
    return null;
  }
  const courseLength = sectionDurationDays * numberOfSections;
  return moment(startDate).add(courseLength, 'days').format(DATE_PRIMARY_CHUNK);
};

export const getDashes = (num: number) => {
  let dashes = (<span />);
  times(num, () => {
    dashes = (<span>{dashes}&ndash;&nbsp;</span>);
  });
  return dashes;
};

export const getCourseBuilderTaxonomies = (
  primaryTaxonomies: PrimaryTaxonomy[],
): string[] => {

  // The primary taxonomies for the main product isbns on this course
  // Note that we can use the primary taxonomies for sub components as well so need
  // to be careful to filter by the main isbns

  if (!primaryTaxonomies || !primaryTaxonomies.length) {
    return [];
  }

  const ids = primaryTaxonomies.reduce((acc, cur) => {

    if (!cur.taxonomy || !cur.taxonomy.data) {
      ELSLoggingService.warn(fileName, 'Missing taxonomy data for isbn', cur.isbn);
      // This should never happen.  Added it for a broken unit test
      return acc;
    }

    if (cur.taxonomy.data.length !== 1) {
      ELSLoggingService.warn(fileName, 'More than 1 primary taxonomy found for ISBN', cur.isbn);
    }

    const taxonomiesForCourseBuilder = cur.taxonomy.data.filter((taxon) => {
      return taxon.attributes && taxon.attributes.priority === 1;
    });

    return [...acc, ...taxonomiesForCourseBuilder.map(mapToIds)];
  }, []);

  if (ids.length) {
    return uniq(ids);
  }

  ELSLoggingService.warn(fileName, 'No primary taxonomies found.');
  return null;
};

export const buildOldCourseSameIsbnsAndClosestToCurrentDate = (courseSections: ELSCourseSectionDto[], currentCourseSection: ELSCourseSectionDto | CourseSectionDto) => {
  const courseEntitlementIsbns = currentCourseSection.entitlements.map(entitlement => entitlement.isbn);
  return uniqBy(courseSections.filter(courseSection => {
    if (courseSection.entitlements.length === 0 || courseSection.id === currentCourseSection.id) {
      return false;
    }
    return courseSection.entitlements.every(entitlement => includes(courseEntitlementIsbns, entitlement.isbn));
  }).sort((a, b) => {
    return b.createdAt - a.createdAt;
  }), 'id');
};

export const buildOldCourseName = (courseSection: ELSCourseSectionDto) => {
  return `${courseSection.courseName} (created on ${moment(courseSection.createdAt).format(DATE_PRIMARY)})`;
};

export const getSherpathClassicEbookReadingTitle = (contentItem: SherpathClassicContentItemDto) => {
  if (!contentItem || contentItem.type !== SherpathContentTypeDto.EBOOK) {
    return null;
  }

  const data: {
    chapters: {
      chapterNumber: string;
      chapterTitle: string;
      checked: boolean;
      pages: string[];
      vtwId: string[];
    }[];
    title: string;
  } = JSON.parse(contentItem.content.contentDetail.overrides);

  const pages = data.chapters.map((chapter) => {
    return `Chapter ${chapter.chapterNumber}, ${chapter.chapterTitle}: pp. ${chapter.pages.join(', ')}`;
  }).join('; ');
  return `${data.title} - ${pages}`;
};

export const getCourseCopyPreviewWarningContentItems = (courseCopyPreviewDto: CourseCopyPreviewDto): CourseCopyPreviewWarningContentItem[] => {
  return courseCopyPreviewDto.resources.reduce((acc, module) => {
    if (module.assessment) {
      return [
        ...acc,
        {
          type: SherpathContentType.NAQ,
          title: module.assessment.title
        }
      ];
    }

    if (module.contentScheduleDetail && module.contentScheduleDetail.length) {
      return module.contentScheduleDetail.reduce((_acc, contentItem) => {

        if (contentItem.type === SherpathContentTypeDto.EBOOK) {
          return [
            ..._acc,
            {
              type: contentItem.type,
              title: getSherpathClassicEbookReadingTitle(contentItem)
            }
          ];
        }

        const data: { title: string } = JSON.parse(contentItem.content.data);
        return [
          ..._acc,
          {
            type: contentItem.type,
            title: data.title
          }
        ];
      }, acc);
    }

    return acc;
  }, []);
};

export const getCourseCopyPreviewWarningTitlesByType = (courseCopyPreviewDto: CourseCopyPreviewDto): Partial<Record<SherpathContentType | SherpathContentTypeDto, string>> => {

  if (!courseCopyPreviewDto || !courseCopyPreviewDto.resources || !courseCopyPreviewDto.resources.length) {
    return {};
  }

  const contentItems = getCourseCopyPreviewWarningContentItems(courseCopyPreviewDto);

  if (!contentItems || !contentItems.length) {
    return {};
  }

  return contentItems.reduce((acc, item) => {
    if (acc[item.type]) {
      return {
        ...acc,
        [item.type]: `${acc[item.type]}, ${item.title}`
      };
    }
    return {
      ...acc,
      [item.type]: item.title
    };
  }, {});
};

export const getCourseCopyPreviewWarningTypeCount = (courseCopyPreviewDto: CourseCopyPreviewDto): Partial<Record<SherpathContentType | SherpathContentTypeDto, number>> => {

  if (!courseCopyPreviewDto || !courseCopyPreviewDto.resources || !courseCopyPreviewDto.resources.length) {
    return {};
  }

  const contentItems = getCourseCopyPreviewWarningContentItems(courseCopyPreviewDto);

  if (!contentItems || !contentItems.length) {
    return {};
  }

  return contentItems.reduce((acc, item) => {
    if (acc[item.type]) {
      return {
        ...acc,
        [item.type]: acc[item.type] + 1
      };
    }
    return {
      ...acc,
      [item.type]: 1
    };
  }, {});
};

export const getCourseCopyPreviewResourceWarningMsg = (typeCountMap: Partial<Record<SherpathContentType | SherpathContentTypeDto, number>>): string => {
  const messageArray = Object.keys(typeCountMap).reduce((acc, type) => {
    let typeDisplay = type;
    if (SherpathContentTypeMap[type]) {
      typeDisplay = typeCountMap[type] === 1 ? SherpathContentTypeMap[type].singular : SherpathContentTypeMap[type].plural;
    }
    return [...acc, `${typeCountMap[type]} ${typeDisplay}`];
  }, []);

  return joinListCommaSeparated(messageArray);
};

export const getAssignmentsFromCourseCopyPreviewDto = (courseCopyPreviewDto: CourseCopyPreviewDto): AssignmentDto[] => {

  if (!courseCopyPreviewDto || !courseCopyPreviewDto.syllabusItemResponse || !courseCopyPreviewDto.syllabusItemResponse.externalEntities) {
    return [];
  }

  return courseCopyPreviewDto.syllabusItemResponse.externalEntities
    .filter((ee) => {
      return ee.externalIdentifier && ee.externalIdentifier.type === ExternalIdTypeDto.ASSIGNMENT_ID;
    })
    .map<AssignmentDto>((ee) => {
      return ee.entity as AssignmentDto;
    });
};

export const getNewCourseClosedFolderIds = (newSyllabusItems: SyllabusItemDto[]) => {

  if (!newSyllabusItems || !newSyllabusItems.length) {
    return [];
  }

  const treeMap = getSortedSyllabusTreeMapItems(newSyllabusItems);

  const firstItem = treeMap.find((item) => {
    return item.syllabusItem.type !== ActiveSyllabusItemTypeDto.FOLDER;
  });

  if (!firstItem) {
    return [];
  }

  const openFolders = getAncestorLineFolderIds(newSyllabusItems, firstItem.syllabusItem.id);

  return newSyllabusItems.filter((syllabusItem) => {
    return syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER;
  }).map(mapToIds).filter((syllabusItemId) => {
    return !openFolders.includes(syllabusItemId);
  });
};

export const getSyllabusItemsForSave = (preview: SyllabusTreeMapItem[]): SyllabusItemDto[] => {
  if (!preview || !preview.length) {
    return [];
  }
  return preview.map((item) => {
    return {
      ...item.syllabusItem,
      title: truncateTitle(item.syllabusItem.title, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
      subtitle: truncateTitle(item.syllabusItem.subtitle, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH)
    };
  });
};
