import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const OSMOSIS_VIDEO_EDITOR_PAGE_TITLE = 'Osmosis Video Assignment';

export enum OsmosisVideoStep {
  STEP1 = 'STEP1',
}

export const OsmosisVieoDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: OsmosisVideoStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
